import { get, isNil } from 'lodash-es';

export const processService = (rawData) => {
    return {
		id: rawData.id,
		type: get(rawData, ":type.name", "privateRoom"),
		name: get(rawData, "name"),
		office:get(rawData,"office"),
		capacity: get(rawData, "capacity"),
		perHour: get(rawData, "perHour"),
		perHourEnabled: isNil(get(rawData, "perHour")) ? false : true,
		perDay: get(rawData, "perDay"),
		perDayEnabled: isNil(get(rawData, "perDay")) ? false : true,
		perWeek: get(rawData, "perWeek"),
		perWeekEnabled: isNil(get(rawData, "perWeek")) ? false : true,
		perMonth: get(rawData, "perMonth"),
		perMonthEnabled: isNil(get(rawData, "perMonth")) ? false : true,
		cancelPercentageEnabled: Boolean(get(rawData, "cancellabled")),
		cancelPercentage: get(rawData, "deposit"),
		active: Boolean(get(rawData, "isActive")),
		features: JSON.parse(
			isNil(get(rawData, "userFeatures"))
				? "[]"
				: get(rawData, "userFeatures")
		).map((item) => ({ name: item })),
		createdAt: get(rawData, "createdAt"),
	};
};
