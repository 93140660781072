import React from 'react'
import Chart from "react-apexcharts";
function OfficeTypeChart(props) {
    const series = [
			{
				name: "Hot desk",
				data: [44, 55, 57, 56, 61, 58],
			},
			{
				name: "Private room",
				data: [76, 85, 101, 98, 87, 105],
			},
			{
				name: "Meeting room",
				data: [35, 41, 36, 26, 45, 48],
			},
			{
				name: "Parking",
				data: [35, 41, 36, 26, 45, 48],
			},
		]
        
    const options = {
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "55%",
				endingShape: "rounded",
				dataLabels: {
					position: "top", // top, center, bottom
				},
			},
		},
		dataLabels: {
			enabled: true,
			offsetY: -20,
			style: {
				fontSize: "12px",
				colors: ["#304758"],
			},
		},
		xaxis: {
			categories: [
				"Jan 2021",
				"Feb 2021",
				"Mar 2021",
				"Apr 2021",
				"May 2021",
				"Jun 2021",
			],
		},
		yaxis: {
			title: {
				text: "Number",
			},
		},
		fill: {
			opacity: 1,
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val;
				},
			},
		},
		title: {
			text: "Total offices by types",
			align: "center",
			style: {
				fontSize: "16",
			},
		},
	};

    return (
		<Chart
			options={options}
			series={series}
			type="bar"
            height="400"
		/>
	);
}

export default OfficeTypeChart
