import * as React from 'react';
import PropTypes from 'prop-types';
import ThumbUp from '@material-ui/icons/ThumbUp';
import {has} from 'lodash'

import {
	Button,
	useUpdateMany,
	useNotify,
	useRefresh,
	useUnselectAll,
	CRUD_UPDATE_MANY,
	BulkActionProps,
	Identifier,
	useListContext,
} from "react-admin";

const noSelection = [];

const BulkAcceptButton = (props) => {
    const { selectedIds = noSelection } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('reviews');
    const {data} = useListContext(props);
    const needUpdateId = selectedIds.filter(id=>{
        if(has(data,id) && data[id].status!=='verified'){
            return true
        }else{
            return false
        }
    })
    const [approve, { loading }] = useUpdateMany(
		"reviews",
		needUpdateId,
		{ status: "verified" },
		{
			action: CRUD_UPDATE_MANY,
			undoable: true,
			onSuccess: () => {
				notify(
					// 'resources.reviews.notification.approved_success',
					"Approve success",
					"info",
					{},
					true
				);
				refresh();
				unselectAll();
			},
			onFailure: () => {
				notify(
					// 'resources.reviews.notification.approved_error',
					"Approve failed",
					"warning"
				);
			},
		}
	);

    return (
        <Button
            // label="resources.reviews.action.accept"
            label="Accept"
            onClick={approve}
            disabled={loading}
        >
            <ThumbUp />
        </Button>
    );
};

BulkAcceptButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkAcceptButton;
