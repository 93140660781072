import * as React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
	linkToRecord,
	ReferenceField,
	FunctionField,
	TextField,
	useListContext,
	Record,
	SimpleList,
} from "react-admin";
import {get} from 'lodash'

// import AvatarField from '../visitors/AvatarField';

const useStyles = makeStyles({
    root: {
        width: '100vw',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    inline: {
        display: 'inline',
    },
});

const OfficeListMobile = () => {
    const classes = useStyles();
    const { basePath, data, ids, loaded, total } = useListContext();

    return (
		<SimpleList
			primaryText={(record) => record.hostName}
			secondaryText={(record) => `${record.reference}`}
			tertiaryText={(record) => record.status}
		/>
	);

    return loaded || Number(total) > 0 ? (
        <List className={classes.root}>
            {(ids).map(id => {
                const item = (data)[id];
                if (!item) return null;
                console.log(item)
                return (
					<Link
						to={linkToRecord(basePath, id)}
						className={classes.link}
						key={id}
					>
						<ListItem button>
							<ListItemAvatar>
								<Avatar
									alt={get(item, "userDetail.name")}
									src={get(item, "userDetail.avatar")}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={
									<Fragment>
										<div>{`${get(
											item,
											"userDetail.name"
										)}`}</div>
									</Fragment>
								}
								secondary={item.comment}
								secondaryTypographyProps={{ noWrap: true }}
							/>
						</ListItem>
					</Link>
				);
            })}
        </List>
    ) : null;
};

OfficeListMobile.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.any,
    hasBulkActions: PropTypes.bool.isRequired,
    ids: PropTypes.array,
    onToggleItem: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

OfficeListMobile.defaultProps = {
    hasBulkActions: false,
    selectedIds: [],
};

export default OfficeListMobile;
