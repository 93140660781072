import {
	Sidebar,
	Layout,
	Menu,
	getResources,
	useGetResourceLabel,
	DashboardMenuItem,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText, Paper, Popover, Typography } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import classnames from "classnames";
import lodashGet from "lodash/get";
import {
	usePopupState,
	bindTrigger,
	bindPopover,
} from "material-ui-popup-state/hooks";

import menu from "../routes/menu";

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const CListItem = ({ path, label, icon, subMenu }) => {
	const history = useHistory();
	const popupState = usePopupState({
		variant: "popover",
		popupId: path,
	});
    console.log(subMenu)
    const classes = useStyles()
    const open = useSelector((state) => state.admin.ui.sidebarOpen);;
	return (
		<div>
			<ListItem
				button
				key={path}
				style={{ width: MENU_WIDTH }}
				onClick={subMenu ? null : () => history.push(path)}
				{...(subMenu ? bindTrigger(popupState) : {})}
			>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={label} style={{ marginLeft: -12 }} />
			</ListItem>
			{subMenu && (
				<Popover
					{...bindPopover(popupState)}
					anchorOrigin={{
						vertical: "top",
						horizontal: open ? "right" : "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					style={{ marginLeft: 6 }}
				>
					<div className={classes.menu}>
						{subMenu.map((item) => (
							<ListItem
								button
								key={path}
								onClick={() => {
									history.push(`${path}/${item.path}`);
									popupState.close();
								}}
								className={classes.menuItem}
							>
								<ListItemText
									primary={item.label}
									style={{ marginLeft: -12 }}
								/>
							</ListItem>
						))}
					</div>
				</Popover>
			)}
		</div>
	);
};

const CustomMenu = (props) => {
    const getResourceLabel = useGetResourceLabel();
    // const resources = useSelector(getResources, shallowEqual);
    const classes = useStyles()
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    return (
		<div
			className={classnames(classes.main, {
				[classes.open]: open,
				[classes.closed]: !open,
			})}
		>
			<List>
				{menu.map((item) => (
					<CListItem
						key={item.label}
						path={item.path}
						label={getResourceLabel(item.label, 2)}
						icon={<item.icon/>}
						subMenu={lodashGet(item, "subMenu")}
					/>
				))}
			</List>
		</div>
	);
};

const useStyles = makeStyles(
	(theme) => ({
		main: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			marginTop: "0.5em",
			marginBottom: "1em",
			[theme.breakpoints.only("xs")]: {
				marginTop: 0,
			},
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
            backgroundColor:theme.palette.background.default,
            height:"100vh",
            zIndex:2,
		},
		open: {
			width: lodashGet(theme, "menu.width", MENU_WIDTH),
		},
		closed: {
			width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH),
		},
        menu:{
            // padding:theme.spacing(2)
        },
        menuItem:{
            paddingLeft:30,
            paddingRight:30,
        }
	}),
	{ name: "RaMenu" }
);
export default CustomMenu;
