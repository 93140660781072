import { get, omit } from "lodash";
import { processAnnouncement } from "../data/announcement";
import { cleanObjectNilValue } from "../utils/clean";

export const announcementApi = (httpClient) => {
	const updateOne_ = async (id, data) => {
		const { result } = await httpClient.put(
			["admin", "announcement", id],
			data
		);
		return id;
	};
	const createOne_ = async ( data) => {
		const { result } = await httpClient.post(
			["admin", "announcement"],
			data
		);
		return result;
	};
	const getOne_ = async (id) => {
		const { result } = await httpClient.get(["admin", "announcement", id]);
		return result;
	};
	const delOne_ = async (id) => {
		const { result } = await httpClient.del(["admin", "announcement", id]);
		return result;
	};

	const getList_ = async (params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const { } = params.filter;
		const { target, id } = params;
		const query = {
			pageSize: perPage,
			pageIndex: page - 1,
		};
		// if (target && id) {
		// 	query[target] = id;
		// }
		const { result } = await httpClient.get(
			["admin", "announcement"],
			cleanObjectNilValue(query)
		);

		return {
			data: result.items.map(processAnnouncement),
			total: result.totalItems,
		};
	};

	return {
		getList: async (params) => {
			return await getList_(params);
		},

		getOne: async (params) => {
			const result = await getOne_(params.id);
			console.log(result);
			return {
				data: processAnnouncement(result),
			};
		},

		getMany: async (params) => {
			const res = await Promise.all(params.ids.map((id) => getOne_(id)));
			console.log(res);
			return {
				data: res.map(processAnnouncement),
			};
		},

		getManyReference: async (params) => {
			console.log("r", params);
			return await getList_(params);
		},

		update: async (params) => {
			await updateOne_(
				params.id,
				omit(get(params, "data"), ["createdAt"])
			);
			return {
				data: processAnnouncement({
					...params.previousData.rawData,
					...params.data,
				}),
			};
		},

		updateMany: async (params) => {
			const res = await Promise.all(
				params.ids.map((id) =>
					updateOne_(id, omit(params.data, ["createdAt"]))
				)
			);
			return {
				data: res.map(processAnnouncement),
			};
		},

		create: async (params) => {
            const data = await createOne_(params.data);
            return {
                data: processAnnouncement(data)
            }
			// console.log(params);
			// const mockData = mockDataMaper[resource] || [];
			// return new Promise((resolve, reject) =>
			// 	setTimeout(() => {
			// 		resolve({
			// 			data: {
			// 				...params.data,
			// 				id: Math.random(),
			// 			},
			// 		});
			// 	}, 1000)
			// );
			// httpClient(`${apiUrl}/${resource}`, {
			// 	method: "POST",
			// 	body: JSON.stringify(params.data),
			// }).then(({ json }) => ({
			// 	data: { ...params.data, id: json.id },
			// })),
		},

		delete: async (params) => {
			const res = await delOne_(params.id);
			return { data: { id: params.id } };
		},

		deleteMany: async (params) => {
			const res = await Promise.all(
				params.ids.map((id) => updateOne_(id, params.data))
			);
			return { data: params.ids };
		},
	};
};
