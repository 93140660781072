import * as React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const ImageField = (props) => {
  const { images } = props
  const [open, setOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  return (
    <div className={props.className}>
        {props.images.map((item, index) => (
            <img
            key={index}
              src={item}
              onClick={() => {
                setPhotoIndex(index);
                setOpen(true);
              }}
            />
        ))}
      {open && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((c) => c + images.length - 1) % images.length
          }
          onMoveNextRequest={() =>
            setPhotoIndex((c) => (c + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default ImageField;
