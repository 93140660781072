import faker from "faker";
import { random } from "lodash";
const types = ['individual','company']
export const client = Array(100)
	.fill(0)
	.map((_, index) => {
		const type = types[random(0,1)]
		return {
			id: index + 1,
			reference: `1000${index+1}`,
			clientName: `${faker.name.firstName()} ${faker.name.lastName()}`,
			clientType: type,
			contact: type === "company" ? faker.phone.phoneNumber() : "",
			mobile: faker.phone.phoneNumber(),
			email: faker.internet.email(),
			bookings: "3/20",
			registration: faker.date.past(),
			lastestBooking: faker.date.past(),
			totalSpending: faker.finance.amount(),
			requests: "0/0",
		};
	});
// export const client = [
// 	{
// 		id: 1,
// 		reference: "100007801",
// 		clientName: "Firstname Lastname",
// 		clientType: "individual",
// 		contact: "",
// 		mobile: 6140080808,
// 		email: "",
// 		bookings: "3/20",
// 		registration: new Date("2020/2/28").toISOString(),
// 		lastestBooking: new Date("2021/10/20").toISOString(),
// 		totalSpending: 8970.21,
// 		requests: "0/0",
// 	},
// 	{
// 		id: 2,
// 		reference: "100007802",
// 		clientName: "ABC test company",
// 		clientType: "Company",
// 		contact: "Betty Admin",
// 		mobile: 6140080808,
// 		email: "betty@abc.com",
// 		bookings: "30/120",
// 		registration: new Date("2020/2/28").toISOString(),
// 		lastestBooking: new Date("2021/10/25").toISOString(),
// 		totalSpending: 18970.21,
// 		requests: "1/5",
// 	},
// 	{
// 		id: 3,
// 		reference: "100007803",
// 		clientName: "Firstname Lastname",
// 		clientType: "Individual",
// 		contact: "",
// 		mobile: 6140080808,
// 		email: "",
// 		bookings: "3/20",
// 		registration: new Date("2020/2/28").toISOString(),
// 		lastestBooking: new Date("2021/10/20").toISOString(),
// 		totalSpending: 8970.21,
// 		requests: "0/0",
// 	},
// 	{
// 		id: 4,
// 		reference: "100007804",
// 		clientName: "ABC test company",
// 		clientType: "Company",
// 		contact: "Betty Admin",
// 		mobile: 6140080808,
// 		email: "betty@abc.com",
// 		bookings: "30/120",
// 		registration: new Date("2020/2/28").toISOString(),
// 		lastestBooking: new Date("2021/10/25").toISOString(),
// 		totalSpending: 18970.21,
// 		requests: "1/5",
// 	},
// 	{
// 		id: 5,
// 		reference: "100007805",
// 		clientName: "Firstname Lastname",
// 		clientType: "Individual",
// 		contact: "",
// 		mobile: 6140080808,
// 		email: "",
// 		bookings: "3/20",
// 		registration: new Date("2020/2/28").toISOString(),
// 		lastestBooking: new Date("2021/10/20").toISOString(),
// 		totalSpending: 8970.21,
// 		requests: "0/0",
// 	},
// 	{
// 		id: 6,
// 		reference: "100007806",
// 		clientName: "ABC test company",
// 		clientType: "Company",
// 		contact: "Betty Admin",
// 		mobile: 6140080808,
// 		email: "betty@abc.com",
// 		bookings: "30/120",
// 		registration: new Date("2020/2/28").toISOString(),
// 		lastestBooking: new Date("2021/10/25").toISOString(),
// 		totalSpending: 18970.21,
// 		requests: "1/5",
// 	},
// ];
