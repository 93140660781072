import * as react from "react";

const TextField = (props) => {
  return (
    <div className={`${props.className}`}>
      <div>
        {props.label !== "" && (
          <label>
            <span>{props.label || props.type || "default label"}</span>
          </label>
        )}
        <div>
          {props.type === "Price" ? (
            <span>{`AU$${props.value || "default value"}.00`}</span>
          ) : (
            <span>{props.value || "default value"}</span>
          )}
        </div>
      </div>
    </div>
  );
};
export default TextField;
