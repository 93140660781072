import { get } from "lodash-es";
import { processUser } from "./user";

export const processServiceFee = (rawData) => {
	return {
		id: get(rawData, ":serviceFee.id"),
		user: get(rawData, "id"),
		rate: get(rawData, ":serviceFee.rate"),
		createdAt: get(rawData, ":serviceFee.createdAt"),
		rawData,
	};
};
