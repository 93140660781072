import * as React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
    linkToRecord,
    ReferenceField,
    FunctionField,
    TextField,
    useListContext,
    Record,
} from 'react-admin';
import {get} from 'lodash'

// import AvatarField from '../visitors/AvatarField';

const useStyles = makeStyles({
    root: {
        width: '100vw',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    inline: {
        display: 'inline',
    },
});

const ReviewListMobile = () => {
    const classes = useStyles();
    const { basePath, data, ids, loaded, total } = useListContext();

    return loaded || Number(total) > 0 ? (
        <List className={classes.root}>
            {(ids).map(id => {
                const item = (data)[id];
                if (!item) return null;
                console.log(item)
                return (
					<Link
						to={linkToRecord(basePath, id)}
						className={classes.link}
						key={id}
					>
						<ListItem button>
							<ListItemAvatar>
								<Avatar
									alt={get(item, "userDetail.name")}
									src={get(item, "userDetail.avatar")}
								/>
							</ListItemAvatar>
							<ListItemText
								primary={
									<Fragment>
										<div>{`${get(
											item,
											"userDetail.name"
										)}`}</div>
										{/* <ReferenceField
                                            record={item}
                                            source="customer_id"
                                            reference="customers"
                                            basePath={basePath}
                                            link={false}
                                        >
                                            <FunctionField
                                                render={(record) =>
                                                    record
                                                        ? `${
                                                              (record)
                                                                  .first_name
                                                          } ${
                                                              (record)
                                                                  .last_name
                                                          }`
                                                        : ''
                                                }
                                                variant="subtitle1"
                                                className={classes.inline}
                                            />
                                        </ReferenceField>{' '} */}
									</Fragment>
								}
								secondary={item.comment}
								secondaryTypographyProps={{ noWrap: true }}
							/>
						</ListItem>
					</Link>
				);
            })}
        </List>
    ) : null;
};

ReviewListMobile.propTypes = {
    basePath: PropTypes.string,
    data: PropTypes.any,
    hasBulkActions: PropTypes.bool.isRequired,
    ids: PropTypes.array,
    onToggleItem: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

ReviewListMobile.defaultProps = {
    hasBulkActions: false,
    selectedIds: [],
};

export default ReviewListMobile;
