import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  TextInput,
  ReferenceInput,
  SelectInput,
  ShowGuesser,
  SimpleShowLayout,
  Show,
  DateField,
  NumberField,
  ImageField,
  FileField,
} from "react-admin";
import ImageLighHouseField from "../../components/customField/ImageLighHouseField";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { showStyles } from "./advertisementStyle";
const OtherOptions = (props) => {
  const { allOptions, classes } = props;
  console.log(classes);
  return allOptions.map((item, index) => {
    return (
      <div key={index} className={classes.otherOptions}>
        <div className={classes.leftCheckBox}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name={item.Checkbox.name}
                  id={item.Checkbox.id}
                  color="secondary"
                  defaultChecked={item.Checkbox.defaultChecked}
                />
              }
              label={item.Checkbox.label}
            />
          </FormGroup>
        </div>
        <div className={classes.rightContent}>
          <SimpleShowLayout>
            <TextField
              source="title"
              className={`${classes.commonInput} ${classes.otherOptionsFirst}`}
            />
            <NumberField
              source="price"
              options={{ style: "currency", currency: "AUD" }}
              className={classes.commonInput}
            />
            <TextField source="description" label="" />
            <ImageLighHouseField source="pictures" src="url" label="" />
          </SimpleShowLayout>
        </div>
      </div>
    );
  });
};
const AdvertisementShow = (props) => {
  const [allOptions, setAllOptions] = React.useState([
    {
      Checkbox: {
        id: "otherOpation1",
        name: "otherOpation1",
        defaultChecked: true,
        label: "",
      },
    },
  ]);
  const classes = showStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout className={classes.createFormStyle}>
        {/* <TextField source="id" label="Advertisement id" /> */}
        <TextField
          source="title"
          label={false}
          className={`${classes.commonInput} ${classes.commonInputFirst}`}
        />
        <NumberField
          source="price"
          options={{ style: "currency", currency: "AUD" }}
          className={classes.commonInput}
        />
        <NumberField
          source="sale"
          options={{ style: "currency", currency: "AUD" }}
          className={classes.commonInput}
        />
        <div className={classes.time}>
          <label>Date</label>
          <div>
            <label>From:</label>
            <DateField source="startDate" label="from" />
          </div>
          <div>
            <label>To:</label>
            <DateField source="endDate" label="to" />
          </div>
        </div>
        <TextField source="stock" className={classes.commonInput} />
        <TextField source="description" label="" />
        <ImageLighHouseField source="pictures" src="url" label="" />
        <FileField
          source="files"
          src="url"
          title="title"
          label=""
          target="_blank"
        />
        <OtherOptions allOptions={allOptions} classes={classes} />
      </SimpleShowLayout>
    </Show>
  );
};

export default AdvertisementShow;
