import * as React from "react";
import { Fragment, useCallback } from "react";
import classnames from "classnames";
import {
	BulkDeleteButton,
	List,
	ListProps,
	BulkActionProps,
	SelectInput,
	SearchInput,
} from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import BookingListDesktop from "./BookingListDesktop";

const BookingBulkActionButtons = (props) => (
	<Fragment>
	</Fragment>
);

const boookingFilters = [
	<SelectInput
		source="status"
		choices={[
			{ id: "pending", name: "Pending" },
			{ id: "finished", name: "Finished" },
			{ id: "cancelled", name: "Cancelled" },
			{ id: "ongoing", name: "Ongoing" },
		]}
	/>,
	<SelectInput
		source="bookingInfo.pricingType"
		label="Type"
		choices={[
			{ id: "hour", name: "Hour" },
			{ id: "day", name: "Day" },
			{ id: "month", name: "Month" },
		]}
	/>,
];

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(["all"], {
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listWithDrawer: {
		marginRight: 400,
	},
	drawerPaper: {
		zIndex: 100,
	},
}));

const BookingList = (props) => {
	const classes = useStyles();
	// const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	// const history = useHistory();

	return (
		<div className={classes.root}>
			<List
				{...props}
				filters={boookingFilters}
				className={classnames(classes.list)}
				perPage={25}
				sort={{ field: "createdAt", order: "DESC" }}
				bulkActionButtons={BookingBulkActionButtons}
			>
				<BookingListDesktop />
			</List>
		</div>
	);
};

export default BookingList;
