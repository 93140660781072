import * as React from "react";
import { Fragment, useCallback } from "react";
import classnames from "classnames";
import {
	BulkDeleteButton,
	List,
	ListProps,
	BulkActionProps,
} from "react-admin";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";
import { Drawer, useMediaQuery, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


import ServiceListDesktop from "./ServiceListDesktop";

const OfficeBulkActionButtons = (props) => (
	<Fragment>
	</Fragment>
);

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(["all"], {
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	listWithDrawer: {
		marginRight: 400,
	},
	drawerPaper: {
		zIndex: 100,
	},
}));

const OfficeList = (props) => {
	const classes = useStyles();
	// const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	// const history = useHistory();

	return (
		<div className={classes.root}>
			<List
				{...props}
				className={classnames(classes.list)}
				perPage={25}
				sort={{ field: "createdAt", order: "DESC" }}
				bulkActionButtons={OfficeBulkActionButtons}
			>
				<ServiceListDesktop />
			</List>
		</div>
	);
};

export default OfficeList;
