import * as React from 'react';
import PropTypes from 'prop-types';
import ThumbDown from '@material-ui/icons/ThumbDown';

import {
	Button,
	useUpdateMany,
	useNotify,
	useRefresh,
	useUnselectAll,
	CRUD_UPDATE_MANY,
	BulkActionProps,
	Identifier,
	useListContext,
} from "react-admin";
import { has } from 'lodash';

const noSelection = [];

const BulkRejectButton = (props ) => {
    const { selectedIds = noSelection } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('reviews');
    const { data } = useListContext(props);
	const needUpdateId = selectedIds.filter((id) => {
		if (has(data, id) && data[id].status !== "rejected") {
			return true;
		} else {
			return false;
		}
	});
	// console.log(needUpdateId, data);
    const [reject, { loading }] = useUpdateMany(
		"reviews",
		needUpdateId,
		{ status: "rejected" },
		{
			action: CRUD_UPDATE_MANY,
			undoable: true,
			onSuccess: () => {
				notify(
					// 'resources.reviews.notification.approved_success',
					"Reject success",
					"info",
					{},
					true
				);
				refresh();
				unselectAll();
			},
			onFailure: () => {
				notify(
					// 'resources.reviews.notification.approved_error',
					"Reject failed",
					"warning"
				);
			},
		}
	);

    return (
        <Button
            // label="resources.reviews.action.reject"
            label="Reject"
            onClick={reject}
            disabled={loading}
        >
            <ThumbDown />
        </Button>
    );
};

BulkRejectButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkRejectButton;
