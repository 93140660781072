import HttpClient from './utils/HttpClient'
// import { getSettings } from "config/app";
import dataProvider from './dataProvider';


export const getSettings = (endpointBase) => {
	let settings = {
		adminEndpoint: `${endpointBase}/api/v1`,
		localStoreKey: "AWAdmin",
		landingPath: "/",
		localeBaseUrl: "/locale",
	};

	return settings;
};

const settings = getSettings(process.env.REACT_APP_API_ENDPOINT_BASE);
const rolesToSet = (roles) => (Array.isArray(roles) ? new Set(roles) : roles);

const authKey = `${settings.localStoreKey}.auth`;

export class APIS {
	httpClient = null;
	constructor() {
		this.settings = settings;
        console.log(authKey);
		const httpClient = new HttpClient(settings.adminEndpoint);
		httpClient.onReponseError = (body) => {
			throw new Error(
				(body.error && body.error.message) || body.error || body
			);
		};
		const auth = localStorage.getItem(authKey);
		this.httpClient = httpClient;
		if (auth) {
			this.onLoggedIn(JSON.parse(auth), true);
		}
	}
	setLoggedInUser = (user) => {
		this.loggedInUser = user;
	};
	onLoggedIn = (auth, noWriteBack) => {
		this.httpClient.onSending = (req) => {
			req.set("Authorization", `Bearer ${auth.token}`);
		};

		// const roles = rolesToSet(auth.user.roles);

		this.loggedInUser = { ...auth.user };
		if (!noWriteBack) {
			localStorage.setItem(authKey, JSON.stringify(auth));
		}
	};
	loggin = async ({ password, username }) => {
		const { result: auth } = await this.httpClient.post(["user", "login"], {
			username,
			password,
		});
		this.onLoggedIn(auth);
        return Promise.resolve();
	};
	getAuthProvider = () => {
		return {
			// called when the user attempts to log in
			login: async ({ username, password }) => {
				// accept all username/password combinations
				localStorage.setItem("username", username);
				return this.loggin({ username, password });
			},
			// called when the user clicks on the logout button
			logout: () => {
				delete this.httpClient.onSending;
				this.loggedInUser = false;
				localStorage.removeItem(authKey);
				localStorage.removeItem("username"); // RA need this key
				return Promise.resolve();
			},
			// called when the API returns an error
			checkError: ({ status }) => {
				if (status === 401 || status === 403) {
					localStorage.removeItem("username");
					return Promise.reject();
				}
				return Promise.resolve();
			},
			// called when the user navigates to a new location, to check for authentication
			checkAuth: () => {
				return localStorage.getItem("username")
					? Promise.resolve()
					: Promise.reject();
			},
			// called when the user navigates to a new location, to check for permissions / roles
			getPermissions: () => Promise.resolve(),
			getIdentity: () => {
				try {
					return Promise.resolve({ id:this.loggedInUser.id, fullName:this.loggedInUser.name, avatar:this.loggedInUser.avatar });
				} catch (error) {
					return Promise.reject(error);
				}
			},
		};
	};
    getDataProvider = ()=>{
        return dataProvider(this.httpClient)
    }
}
