import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	Create,
	EditGuesser
} from "react-admin";

const UserCreate = (props) => (
	<Create {...props}>
		<SimpleForm>
			<TextInput source="reference" />
			<TextInput source="clientName" />
			<SelectInput
				source="clientType"
				choices={[
					{ id: "individual", name: "Individual" },
					{ id: "company", name: "Company" },
				]}
			/>
			<TextInput source="contact" />
			<TextInput source="mobile" />
			<TextInput source="email" />
		</SimpleForm>
	</Create>
);

export default UserCreate;
