import faker from 'faker'
import { random } from 'lodash'
const status = ['verified','rejected','pending']
export const reviews = Array(231)
	.fill(0)
	.map((_, index) => ({
		id: index + 1,
		date: faker.date.past(),
		rating: random(1, 5),
		comment: faker.lorem.paragraph(),
		status: status[random(0, 2)],
        user:{
            id:index,
            firstName:faker.name.firstName(),
            lastName:faker.name.lastName(),
            avatar:faker.image.avatar()
        }
	}));
