import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { useTranslate, useUpdate, useNotify, useRedirect } from 'react-admin';
import get from 'lodash/get'
/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton = ({ record }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const redirectTo = useRedirect();

    const [approve, { loading }] = useUpdate(
        'reviews',
        record.id,
        { status: 'verified' },
        record,
        {
            undoable: true,
            onSuccess: () => {
                notify(
                    'Accept success',
                    // 'resources.reviews.notification.approved_success',
                    'info',
                    {},
                    true
                );
                redirectTo('/reviews');
            },
            onFailure: () => {
                notify(
                    'Accept failed',
                    // 'resources.reviews.notification.approved_error',
                    'warning'
                );
            },
        }
    );
    return record ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={approve}
            disabled={loading||get(record,'status',null)==='verified'}
        >
            <ThumbUp
                color="primary"
                style={{ paddingRight: '0.5em', color: 'green' }}
            />
            {/* {translate('resources.reviews.action.accept')} */}
            Accpet
        </Button>
    ) : (
        <span />
    );
};

AcceptButton.propTypes = {
    record: PropTypes.any,
};

export default AcceptButton;
