import { ifThen } from '@genx/july'
import * as React from 'react'

const FileField = (props)=>{
  return (
    <div className={props.className}>
      <label>
        {props.label}
      </label>
      <ul>
        {
          props.files.map((item,index)=>(
            <li key={index}>
              <a href={item.url}>{item.title}</a>
            </li>
          ))
        }
      </ul>
    </div>
  )
}
export default FileField