import { TextField } from '@material-ui/core';
import React from 'react'
import { useInput, Labeled, InputHelperText, FieldTitle } from "react-admin";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
// import { useField } from "react-final-form";

const CurrencyInput = (props) => {
    const { helperText, label, source, resource, className } = props;
    const {
		input: { name, onChange, ...rest },
		meta: { touched, error, submitError },
		isRequired,
	} = useInput(props);
    return (
		<CurrencyTextField
			name={name}
			variant="filled"
			currencySymbol="A$"
			minimumValue="0"
			// outputFormat="number"
			decimalCharacter="."
			digitGroupSeparator=","
			onChange={onChange}
			error={!!(touched && error)}
			margin="dense"
			className={className}
			helperText={
				<InputHelperText
					touched={touched}
					error={error || submitError}
					helperText={helperText}
				/>
			}
			label={
				<FieldTitle
					label={label}
					source={source}
					resource={resource}
					isRequired={isRequired}
				/>
			}
            style={{width:256}}
			{...rest}
		/>
	);
	// return (
	// 	<TextField
	// 		name={name}
	// 		label={props.label||props.source}
	// 		onChange={onChange}
	// 		error={!!(touched && error)}
	// 		helperText={touched && error}
	// 		required={isRequired}
	// 		{...rest}
	// 	/>
	// );
}

export default CurrencyInput

