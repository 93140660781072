import * as React from "react";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { TextField, Button, Stack, Select, MenuItem } from "@mui/material";
import { CreateOptionStyles } from "./advertisementStyle";
const CreateOptions = (props) => {
  const classes = CreateOptionStyles();
  const changePosition = (e, index) => {
    const changed = [...positions];
    changed[index].Position = e.target.value;
    setPositions(changed);
  };
  const changePrice = (e, index, priceField) => {
    const changed = [...positions];
    changed[index].Price[priceField] = e.target.value;
    setPositions(changed);
    console.log(positions);
  };
  const addPosition = (e) => {
    const changed = [...positions];
    const lastChild = changed[changed.length - 1] || 0;
    changed.push({
      Price: {
        day: "",
        mouth: "",
        quarter: "",
      },
    });
    changed[changed.length - 1].id = lastChild.id + 1 || 1;
    setPositions(changed);
  };
  const removePosition = (e, index) => {
    console.log(index);
    const changed = [...positions];
    changed.splice(index, 1);
    setPositions(changed);
  };
  const [positions, setPositions] = React.useState([
    {
      id: 1,
      Position: "App Home",
      Price: {
        day: "1000",
        mouth: "2000",
        quarter: "3000",
      },
    },
  ]);
  const [time, setTime] = React.useState("day");
  const changeTime = (e) => {
    setTime(e.target.value);
    console.log(time);
  };
  return (
    <Card>
      <CardContent className={classes.createFormStyle}>
        {positions.map((item, index) => {
          return (
            <div className={classes.LayoutTextField} key={item.id}>
              <TextField
                label="Position"
                className={classes.TextField}
                defaultValue={item.Position}
                onChange={(e) => changePosition(e, index)}
              />
              <div className="price">
                <TextField
                  label="Price/AU$"
                  className={classes.TextField}
                  value={item.Price[time]}
                  onChange={(e) => changePrice(e, index, time)}
                />
                <Select
                  className={classes.Select}
                  value={time}
                  onChange={(e) => changeTime(e)}
                >
                  <MenuItem value="day">day</MenuItem>
                  <MenuItem value="mouth">mouth</MenuItem>
                  <MenuItem value="quarter">quarter</MenuItem>
                </Select>
              </div>
              <Button
                onClick={(e) => removePosition(e, index)}
                className={classes.removeButton}
              >
                Remove
              </Button>
            </div>
          );
        })}
        <Stack className={classes.Stack}>
          <Button variant="contained" onClick={(e) => addPosition()}>
            Add
          </Button>
        </Stack>
        <Stack className={classes.Stack2}>
          <Button variant="contained" className={classes.Button}>
            Save
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default CreateOptions;
