import {get} from 'lodash'
import { isNil } from 'lodash-es';
import { getLocalDateTimeFromMinutes } from '../utils/time';
import { processService } from './service';
import { processUser } from './user';

export const processBookingDetail = (rawData)=>{
    const isHourBooking = get(rawData, 'bookingType') === 'H';
    return {
        id: get(rawData, 'id'),
        status: get(rawData, 'status'),
        office: get(rawData, ':asset.office'),
        service: get(rawData, 'asset'),
        userDetail:processUser(get(rawData, ':client')),
        serviceDetail:processService(get(rawData, ':asset')),
        bookingInfo: {
            createdAt:get(rawData,'createdAt'),
            user: get(rawData, 'client'),
            userDetail: processUser(get(rawData, ':client')),
            contact: get(rawData, 'contact'),
            reference: get(rawData, 'reference'),
            quantity: get(rawData, 'quantity'),
            isCompanyBooking:Boolean(get(rawData,'isCompany',false)),
            isHourBooking,
            pricingType:isHourBooking?'hour':get(rawData, 'bookingType') === 'D'?'day':'month',
            bookingTime: {
                start: isHourBooking
                    ? getLocalDateTimeFromMinutes(
                          get(rawData, 'startTime', 0),
                          get(rawData, 'startDate')
                      ).toISOString()
                    : null,
                end: isHourBooking
                    ? getLocalDateTimeFromMinutes(
                          get(rawData, 'endTime', 0),
                          get(rawData, 'startDate')
                      ).toISOString()
                    : null,
            },
            bookingDate: {
                start: get(rawData, 'startDate'),
                end: get(rawData, 'endDate'),
            },
            noteForHost: get(rawData, 'note'),
            noteFromHost: get(rawData, 'noteFromHost'),
            confidentialNotesAfterCheckin: get(
                rawData,
                'confidentialNotesAfterCheckin'
            ),
        },
    };
}


export const processBookingList = (listRawData)=>{
    return (listRawData||[]).map(processBookingDetail)
}