import React from "react";
import Chart from "react-apexcharts";
import { formatCurrency } from "../../utils/formatter";

const formatter = (value)=>formatCurrency("AUD", value);
function RevenueAndFeeChart(props) {
	const series = [
		{
			name: "Booking fees",
			data: [
				139500, 155000, 180800, 157200, 135800, 154000, 166600, 159500, 147790, 152900, 151500, 89400,
			],
		},
		{
			name: "Platform revenue",
			data: [16740, 18600, 21696, 18864, 16296, 18480, 19992, 19140, 17748, 18348, 18180, 10780],
		}
	];

	const options = {
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "55%",
				endingShape: "rounded",
				dataLabels: {
					position: "top", // top, center, bottom
				},
			},
		},
		dataLabels: {
			enabled: false,
			offsetY: -20,
			style: {
				fontSize: "12px",
				colors: ["#304758"],
			},
			formatter,
		},
		xaxis: {
			categories: [
				"Jan 2021",
				"Feb 2021",
				"Mar 2021",
				"Apr 2021",
				"May 2021",
				"Jun 2021",
				"Jul 2021",
				"Aug 2021",
				"Sep 2021",
				"Oct 2021",
				"Nov 2021",
				"Dec 2021",
			],
		},
		yaxis: {
			title: {
				text: "Amount",
			},
			labels: {
				formatter,
			},
		},
		fill: {
			opacity: 1,
		},
		tooltip: {
			y: {
				formatter,
			},
		},
		title: {
			text: "Booking fees / Platform revenue",
			align: "center",
			style: {
				fontSize: "16",
			},
		},
	};

	return <Chart options={options} series={series} type="bar" height="400" />;
}

export default RevenueAndFeeChart;
