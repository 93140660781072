import { isNil,get } from "lodash-es";
export const processOfficeListItem = (rawData) =>{
    return {
		id: get(rawData, "id"),
		reference: get(rawData, "reference"),
		hostName: get(rawData, "name"),
		address: get(rawData, "address"),
		city: get(rawData, "city"),
		postcode: get(rawData, "postcode"),
		bookings: `${get(rawData, "processingBooking")}/${get(
			rawData,
			"totalBooking"
		)}`,
		registration: get(rawData, "registration"),
		latestBooking: get(rawData, "latestBooking"),
		totalEarning: Number(get(rawData, "totalEarning", 0)),
		status: get(rawData, "status"),
	};
}