import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext, Labeled } from "react-admin";
import { get } from "lodash";
import ReactPlayer from "react-player/lazy";

const VideosField = (props) => {
	const { source, src = "url" } = props;
	const record = useRecordContext(props);
	const data = get(record,source)
	const videos = data?data.map((item) => item[src]):[]
	return (
		<div>
			{videos.map((url, i) => (
				<ReactPlayer key={i} url={url} style={styles.video} controls />
			))}
		</div>
	);
};

VideosField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};
VideosField.defaultProps = {
	addLabel: true,
};

const styles = {
	image: {
		height: 150,
		marginRight: 16,
		objectFit: "contain",
		cursor: "pointer",
	},
	video:{
		// maxWidth:500,
		// aspectRatio: 16 / 9,
		// height:"auto"
		marginBottom:16
	}
};

export default VideosField;
