import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	BooleanField,
	TextInput,
	ReferenceInput,
	SelectInput,
	ShowGuesser,
	SelectField,
	SimpleShowLayout,
	Show,
	DateField,
	ReferenceManyField,
	NumberField,
	FunctionField,
	SingleFieldList,
	ChipField,
	ArrayField,
	TabbedShowLayout,
	Tab,
	ImageField,
	Pagination,
} from "react-admin";
import { format, parseISO } from "date-fns";
import { get } from "lodash";
import UserField from "../../components/customField/UserField";

const BookingShow = (props) => (
	<Show {...props}>
		<TabbedShowLayout>
			<Tab label="Detail">
				<TextField source="id" />
				<DateField
					source="bookingInfo.createdAt"
					label="Date"
					sortable={false}
				/>
				<TextField
					source="bookingInfo.reference"
					label="Reference"
					sortable={false}
				/>
				<UserField
					source="userDetail"
					label="Client"
					sortable={false}
				/>
				<TextField
					source="serviceDetail.name"
					label="Service"
					sortable={false}
				/>
				<TextField
					source="bookingInfo.quantity"
					label="Quantity"
					sortable={false}
				/>
				<BooleanField
					source="bookingInfo.isCompanyBooking"
					label="Comapny booking"
					sortable={false}
				/>
				<SelectField
					sortable={false}
					source="bookingInfo.pricingType"
					label="Type"
					choices={[
						{ id: "hour", name: "Hour" },
						{ id: "day", name: "Day" },
						{ id: "month", name: "Month" },
					]}
				/>
				<FunctionField
					source="bookingInfo.bookingDate.start"
					label="Start date time"
					sortable={false}
					render={(record) => {
						const time =
							get(record, "bookingInfo.bookingTime.start") ||
							get(record, "bookingInfo.bookingDate.start");
						const isHourBooking = get(
							record,
							"bookingInfo.isHourBooking",
							false
						);
						return format(
							new Date(time),
							isHourBooking ? "MM/dd/yyyy p" : "MM/dd/yyyy"
						);
					}}
				/>
				<FunctionField
					source="bookingInfo.bookingDate.start"
					label="End date time"
					sortable={false}
					render={(record) => {
						const time =
							get(record, "bookingInfo.bookingTime.end") ||
							get(record, "bookingInfo.bookingDate.end");
						const isHourBooking = get(
							record,
							"bookingInfo.isHourBooking",
							false
						);
						return format(
							new Date(time),
							isHourBooking ? "MM/dd/yyyy p" : "MM/dd/yyyy"
						);
					}}
				/>
				<SelectField
					sortable={false}
					source="status"
					label="Status"
					choices={[
						{ id: "pending", name: "Pending" },
						{ id: "finished", name: "Finished" },
						{ id: "cancelled", name: "Cancelled" },
						{ id: "ongoing", name: "Ongoing" },
					]}
				/>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default BookingShow;
