import React from 'react'
import Chart from "react-apexcharts";
function ClientsChart(props) {
    const series = [
            {
                name: "Increments",
                type: "column",
                data: [80, 120, 160, 200, 320, 100],
            },
            {
                name: "Clients",
                type: "line",
                data: [1000,1120,1280,1480,1700,1800],
            },
        ]
        
    const options = {
		chart: {
			height: 350,
			type: "line",
			toolbar: {
				tools: {
					download: true,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
				},
			},
		},
		stroke: {
			width: [0, 4],
		},
		title: {
			text: "Clients (Total/incremental)",
			align: "center",
			style: {
				fontSize: "16",
			},
		},
		dataLabels: {
			enabled: true,
			enabledOnSeries: [1],
		},
		labels: [
			"Jan 2021",
			"Feb 2021",
			"Mar 2021",
			"Apr 2021",
			"May 2021",
			"Jun 2021",
		],
		xaxis: {
			type: "datetime",
		},
		yaxis: [
			{
				opposite: true,
				title: {
					text: "Increments",
				},
			},
			{
				title: {
					text: "Clients",
				},
			},
		],
	};
    return (
		<Chart
			options={options}
			series={series}
			type="line"
            height="400"
		/>
	);
}

export default ClientsChart
