import * as React from "react";
import {
	Card,
	Paper,
	Grid,
	makeStyles,
} from "@material-ui/core";
import ClientsChart from "../../components/charts/ClientsChart";
import HostChart from "../../components/charts/HostChart";
import OfficeTypeChart from "../../components/charts/OfficeTypeChart";
import BookingChart from "../../components/charts/BookingChart";
import RevenueAndFeeChart from "../../components/charts/RevenueAndFeeChart";

export default () => {
	const classes = useStyles()
	return (
		<div>
			<div className={classes.root}>
				<Grid container spacing={2}>
					<Grid item sm={12} md={6}>
						<Paper className={classes.paper}>
							<ClientsChart />
						</Paper>
					</Grid>
					<Grid item sm={12} md={6}>
						<Paper className={classes.paper}>
							<HostChart />
						</Paper>
					</Grid>
					<Grid item sm={12}>
						<Paper className={classes.paper}>
							<OfficeTypeChart />
						</Paper>
					</Grid>
					<Grid item sm={12}>
						<Paper className={classes.paper}>
							<BookingChart />
						</Paper>
					</Grid>
					<Grid item sm={12}>
						<Paper className={classes.paper}>
							<RevenueAndFeeChart />
						</Paper>
					</Grid>
				</Grid>
			</div>
		</div>
	);
	
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		// textAlign: "center",
		// color: theme.palette.text.secondary,
		// shadow
	},
}));
