import { get, has } from "lodash";

const PreviewImage = ({ record, source, preSaveSource='url' }) => {
	if (!has(record, "rawFile")) {
		record = {
			[source]: get(record, preSaveSource),
		};
	}
	return (
		<img
			src={get(record, source)}
			style={{ margin: "0.5rem", maxHeight: "10rem" }}
		/>
	);
};
export default PreviewImage;