import { get } from "lodash";
import { processComment } from "../data/comment";
import { processPlatformSettings } from "../data/platformSettings";

export const systemSettingApi = (httpClient) => {
	const updateOne_ = async (key, data) => {
		const { result } = await httpClient.put(
			["admin", "setting", key],
			data
		);
		return key;
	};
	const getOne_ = async (id) => {
		const { result } = await httpClient.get(["admin", "setting"]);
		return result;
	};

	const getList_ = async (params) => {
		const { result } = await httpClient.get(["admin", "setting"]);
		return {
			data: [processPlatformSettings(result)],
			total: 1,
		};
	};

	return {
		getList: async (params) => {
			return await getList_(params);
		},

		getOne: async (params) => {
			const result = await getOne_(params.id);
			return {
				data: processPlatformSettings(result),
			};
		},

		getMany: async (params) => {
		},

		getManyReference: async (params) => {
		},

		update: async (params) => {
            // console.log("update system", params);
            const updatedField = []
            Object.keys(params.data).map(key=>{
                if(params.previousData[key]!==params.data[key]){
                    updatedField.push({ key, value: params.data[key] });
                }
            })
            await Promise.all(updatedField.map(kv=>updateOne_(kv.key,{value:kv.value})));
			return {
				data: params.data,
			};
		},

		updateMany: async (params) => {
		},

		create: (resource, params) => {
		
		},

		delete: async (params) => {
		},

		deleteMany: async (params) => {
		},
	};
};
