import React from "react";
import Chart from "react-apexcharts";
function OfficeTypeChart(props) {
	const series = [
		{
			name: "Hot desk",
			data: [600, 800, 1000, 900, 600, 800,1000,1200,900,1000,800,300],
		},
		{
			name: "Private room",
			data: [300,400,450,450,320,180,200,230,180,150,200,120],
		},
		{
			name: "Meeting room",
			data: [500,450,500,360,400,600,640,450,500,530,600,420],
		},
		{
			name: "Parking",
			data: [650,700,860,800,860,800,700,750,830,810,650,400],
		},
	];

	const options = {
		chart: {
			type: "bar",
			height: 350,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "55%",
				endingShape: "rounded",
				dataLabels: {
					position: "top", // top, center, bottom
				},
			},
		},
		stroke: {
			show: true,
			width: 4,
			colors: ["transparent"],
		},
		dataLabels: {
			enabled: false,
			offsetY: -20,
			style: {
				fontSize: "12px",
				colors: ["#304758"],
			},
		},
		xaxis: {
			categories: [
				"Jan 2021",
				"Feb 2021",
				"Mar 2021",
				"Apr 2021",
				"May 2021",
				"Jun 2021",
				"Jul 2021",
				"Aug 2021",
				"Sep 2021",
				"Oct 2021",
				"Nov 2021",
				"Dec 2021",
			],
		},
		yaxis: {
			title: {
				text: "Number",
			},
		},
		fill: {
			opacity: 1,
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return val;
				},
			},
		},
		title: {
			text: "Office bookings",
			align: "center",
			style: {
				fontSize: "16",
			},
		},
	};

	return <Chart options={options} series={series} type="bar" height="400" />;
}

export default OfficeTypeChart;
