import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  ImageInput,
  NumberInput,
  DateInput,
  ImageField,
  FileInput,
  FileField,
  useRecordContext,
  required,
  Create,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  SaveButton,
  Toolbar,
} from "react-admin";

import PreviewImage from "../../components/customField/PreviewImage";
import PreviewFile from "../../components/customField/PreviewFile";
import CurrencyInput from "../../components/customInput/CurrencyInput";
import numeral from "numeral";
import { createStyles } from "./advertisementStyle";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
const createToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label="post.action.save_and_show"
        redirect="show"
        submitOnEnter={true}
      />
      <SaveButton
        label="post.action.save_and_add"
        redirect={false}
        submitOnEnter={false}
        variant="text"
      />
    </Toolbar>
  );
};
const AdvertisementCreate = (props) => {
  const [positions, setPositions] = React.useState([
    {}
  ])
  const classes = createStyles();
  return (
    <Create {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        className={classes.createFormStyle}
        // toolbar={<createToolbar />}
      >
        <TextInput
          source="title"
          className={`${classes.createTitle} ${classes.createPageTitle}`}
        />
        <CurrencyInput
          source="price"
          validate={[required()]}
          parse={(inputValue) => numeral(inputValue).value()}
          className={`${classes.createTitle} ${classes.createPrice}`}
        />
        <CurrencyInput
          source="sale"
          validate={[required()]}
          parse={(inputValue) => numeral(inputValue).value()}
          className={`${classes.createTitle} ${classes.createPrice}`}
        />
        <NumberInput source="stock" className={classes.createTitle} />
        <div className={classes.choiceTime}>
          <DateInput source="startDate" className={classes.createTitle} />
          <DateInput source="endDate" className={classes.createTitle} />
        </div>
        {positions.map((item, index) => {
          return (
            <div className={classes.positionPriceGroup} key={index}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="inCluedInStandardPrice"
                      id="inCluedInStandardPrice"
                      color="secondary"
                      defaultChecked={true}
                    />
                  }
                  label=""
                />
              </FormGroup>
              <div className={classes.positionPrice}>
                <TextInput
                  source="position"
                  className={`${classes.createTitle} ${classes.createPageTitle}`}
                />
                <div className={classes.price}>
                  <TextInput
                    source="price"
                    className={`${classes.createTitle} ${classes.createPageTitle}`}
                  />
                  <SelectInput
                    source="price"
                    choices={[
                      {
                        id: "day",
                        name: "day",
                      },
                      {
                        id: "mouth",
                        name: "mouth",
                      },
                      {
                        id: "quarter",
                        name: "quarter",
                      },
                    ]}
                    label=""
                    className={`${classes.createTitle} ${classes.createPageTitle} ${classes.select}`}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <TextInput
          source="description"
          multiline
          resettable
          fullWidth
          className={`${classes.createTitle} ${classes.createTextarea}`}
        />
        <ImageInput
          source="pictures"
          accept="image/*"
          multiple
          className={classes.createPageFile}
        >
          <PreviewImage source="src" preSaveSource="url" />
        </ImageInput>
        <FileInput source="files" multiple className={classes.createPageFile}>
          <PreviewFile source="src" />
        </FileInput>

        <ArrayInput source="options" className={classes.otherOptionBlock}>
          <SimpleFormIterator>
            <TextInput
              label="Title"
              source="title"
              className={`${classes.createTitle} ${classes.createPageTitle}`}
            />
            <div className={classes.optionPrice}>
              <CurrencyInput
                label="Price"
                source="price"
                validate={[required()]}
                parse={(inputValue) => numeral(inputValue).value()}
                className={`${classes.createTitle} ${classes.createPrice}`}
              />
              {/* <BooleanInput
              label="Included in standard price"
              source="inCluedInStandardPrice"
            /> */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="inCluedInStandardPrice"
                      id="inCluedInStandardPrice"
                      color="secondary"
                      defaultChecked={true}
                    />
                  }
                  label="Included in standard price"
                />
              </FormGroup>
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox name="default" id="default" color="secondary" />
                }
                label="Default"
              />
            </FormGroup>
            {/* <BooleanInput label="Default" source="default" /> */}
            <TextInput
              label="Description"
              source="description"
              multiline
              resettable
              fullWidth
              className={`${classes.createTitle} ${classes.createTextarea}`}
            />
            <ImageInput
              label="Pictures"
              source="pictures"
              accept="image/*"
              multiple
              className={classes.createPageFile}
            >
              <PreviewImage source="src" preSaveSource="url" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default AdvertisementCreate;
