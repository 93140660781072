import { get } from "lodash-es"

export const processUser = (userRaw)=>{
    return {
        id: get(userRaw, 'id'),
        name: get(userRaw, 'name'),
        gender: get(userRaw, ':person.gender'),
        dob: get(userRaw, ':person.dob'),
        mobile: get(userRaw, 'mobile'),
        email: get(userRaw, 'email'),
        title: get(userRaw, 'title'),
        avatar: get(userRaw, 'avatar'),
    };
}