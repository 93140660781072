import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	BooleanInput,
	DateInput,
	TabbedForm,
	FormTab,
	required,
} from "react-admin";

const AnnouncementEdit = (props) => (
	<Edit {...props}>
		<TabbedForm
			warnWhenUnsavedChanges
			sanitizeEmptyValues={false}
			redirect="show"
		>
			<FormTab label="Detail">
				<TextInput source="id" disabled />
				<DateInput
					source="expiryDate"
					label="Expiry date"
					validate={[required()]}
				/>
				<TextInput source="title" resettable validate={[required()]} />
				<TextInput
					source="content"
					multiline
					resettable
					fullWidth
					validate={[required()]}
				/>
				<BooleanInput source="isTop" label="Is top" />
				<SelectInput
					source="type"
					label="Type"
					validate={[required()]}
					choices={[
						{ id: "host", name: "Host" },
						{ id: "platform", name: "Platform" },
					]}
				/>
			</FormTab>
		</TabbedForm>
	</Edit>
);

export default AnnouncementEdit;
