import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext, Labeled } from "react-admin";
import { get } from "lodash";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ImageLighHouseField = (props) => {
	const { source, src = "url" } = props;
	const record = useRecordContext(props);
	const data = get(record,source)
	const [open, setOpen] = React.useState(false);
	const [photoIndex, setPhotoIndex] = React.useState(0);
	const images = data?data.map((item) => item[src]):[]
	return (
		<div>
			{(data||[]).map((item, index) => (
				<img
					key={index}
					src={get(item, src)}
					style={styles.image}
					onClick={() => {
						setPhotoIndex(index);
						setOpen(true);
					}}
				/>
			))}
			{open && (
				<Lightbox
					mainSrc={images[photoIndex]}
					nextSrc={images[(photoIndex + 1) % images.length]}
					prevSrc={
						images[(photoIndex + images.length - 1) % images.length]
					}
					onCloseRequest={() => setOpen(false)}
					onMovePrevRequest={() =>
						setPhotoIndex((c) => c + images.length - 1) %
						images.length
					}
					onMoveNextRequest={() =>
						setPhotoIndex((c) => (c + 1) % images.length)
					}
				/>
			)}
		</div>
	);
};

ImageLighHouseField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};
ImageLighHouseField.defaultProps = {
	addLabel: true,
};

const styles = {
	image: {
		height: 150,
		marginRight: 16,
		objectFit: "contain",
		cursor: "pointer",
	},
};

export default ImageLighHouseField;
