import { get } from "lodash";
import { processBookingDetail } from "../data/booking";
import { cleanObjectNilValue } from "../utils/clean";


export const bookingsApi = (httpClient) => {
    const updateOne_ = async (id,data)=>{
        const { result } = await httpClient.put(["admin", "booking", id], data);
        return id
    }
    const getOne_ = async (id)=>{
        const { result } = await httpClient.get(["admin", "booking", id]);
        return result;
    }
    const delOne_ = async (id)=>{
        const { result } = await httpClient.del(["admin", "booking", id]);
        return result;
    }
    const typeMapper = {
        hour:'H',
        day:'D',
        month:'M'
    }

    const getList_ = async (params)=>{
        const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
        const {
			status,
			bookingInfo
		} = params.filter;
        const { target,id } = params;
        console.log(params.filter);
        const query = {
			pageSize: perPage,
			pageIndex: page - 1,
			status,
			bookingType: typeMapper[get(bookingInfo,'pricingType')]||null,
		};
        if(target && id){
            query[target] = id
        }
		const { result } = await httpClient.get(["admin", "booking"], cleanObjectNilValue(query));

		return {
			data: result.items.map(processBookingDetail),
			total: result.totalItems,
		};
    }
    
    return {
        getList: async (params) => {
            return await getList_(params)
        },

        getOne: async (params) => {
            const result = await getOne_(params.id)
            console.log(result)
            return {
				data: processBookingDetail(result),
			};
        },

        getMany: async (params) => {
            const res = await Promise.all(
                params.ids.map((id) => getOne_(id))
            );
            console.log(res)
            return {
                data: res.map(processBookingDetail),
            }; 
        },

        getManyReference: async (params) => {
            console.log('r',params)
            return await getList_(params);
        },

        update: async (params) => {
            await updateOne_(params.id, {
				status: get(params, "data.status"),
			});
            return {
				data: processBookingDetail({
					...params.previousData.rawData,
                    ...params.data
				}),
			};
        },

        updateMany: async (params) => {
            const res = await Promise.all(
				params.ids.map((id) => updateOne_(id, params.data))
			);
            return {
				data: res.map(processBookingDetail),
			};
        },

        create: (resource, params) => {
            // console.log(params);
            // const mockData = mockDataMaper[resource] || [];
            // return new Promise((resolve, reject) =>
            // 	setTimeout(() => {
            // 		resolve({
            // 			data: {
            // 				...params.data,
            // 				id: Math.random(),
            // 			},
            // 		});
            // 	}, 1000)
            // );
            // httpClient(`${apiUrl}/${resource}`, {
            // 	method: "POST",
            // 	body: JSON.stringify(params.data),
            // }).then(({ json }) => ({
            // 	data: { ...params.data, id: json.id },
            // })),
        },

        delete: async (params) => {
            const res = await delOne_(params.id)
            return {data:{id:params.id}}
        },

        deleteMany: async (params) => {
            const res = await Promise.all(
				params.ids.map((id) => updateOne_(id, params.data))
			);
            return { data: params.ids };
        },
    }
};