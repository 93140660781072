import Create from "./AdvertisementCreate";
import Edit from "./AdvertisementEdit";
import List from "./AdvertisementList";
import Show from "./AdvertisementShow";

export default {
	list: List,
	create: Create,
	edit: Edit,
	show: Show,
};
