import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
} from "react-admin";

const UserEdit = (props) => (
	<Edit {...props}>
		<SimpleForm warnWhenUnsavedChanges >
			<TextInput source="id" disabled />
			<TextInput source="reference" />
			<TextInput source="clientName" />
			<SelectInput
				source="clientType"
				choices={[
					{ id: "Individual", name: "Individual" },
					{ id: "Company", name: "Company" },
				]}
			/>
			<TextInput source="contact" />
			<TextInput source="mobile" />
			<TextInput source="email" />
		</SimpleForm>
	</Edit>
);

export default UserEdit;
