export const advertisement = [
	{
		id: 1,
		title: "Test production",
		price: 100000,
		sale: 80000,
		startDate: new Date("2021/10/28").toISOString(),
		endDate: new Date("2021/12/28").toISOString(),
		stock: 100,
		description:
			"test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description ",
		pictures: [
			{
				url: "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG1hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
				desc: "First image",
			},
			{
				url: "https://www.gettyimages.com/gi-resources/images/500px/983794168.jpg",
				desc: "First image",
			},
		],
		files: [
			{
				url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
				title: "test file",
			},
		],
	},
];