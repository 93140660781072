export const host = [
	{
		id: 1,
		reference: "1001201",
		hostName: "Host company1",
		hostType: "Company",
		contact: "Contact 01",
		mobile: 6140080808,
		email: "contact01@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
	{
		id: 2,
		reference: "1001202",
		hostName: "Host company2",
		hostType: "Company",
		contact: "Contact 02",
		mobile: 6140080808,
		email: "contact02@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
	{
		id: 3,
		reference: "1001203",
		hostName: "Host company3",
		hostType: "Company",
		contact: "Contact 03",
		mobile: 6140080808,
		email: "contact03@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
	{
		id: 4,
		reference: "1001204",
		hostName: "Host company4",
		hostType: "Agency",
		contact: "Contact 04",
		mobile: 6140080808,
		email: "contact04@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
	{
		id: 5,
		reference: "1001205",
		hostName: "Host company5",
		hostType: "Agency",
		contact: "Contact 05",
		mobile: 6140080808,
		email: "contact05@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
	{
		id: 6,
		reference: "1001206",
		hostName: "Host company6",
		hostType: "Agency",
		contact: "Contact 06",
		mobile: 6140080808,
		email: "contact06@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
	{
		id: 7,
		reference: "1001207",
		hostName: "Host company7",
		hostType: "Company",
		contact: "Contact 07",
		mobile: 6140080808,
		email: "contact07@test.com",
		bookings: "3/20",
		registration: new Date("2020/2/28").toISOString(),
		lastestBooking: new Date("2021/10/20").toISOString(),
		totalEarning: 8970.21,
		requests: "0/0",
	},
];