import * as React from 'react';
import {
	Identifier,
	Datagrid,
	DateField,
	TextField,
	DatagridProps,
	NumberField,
	EditButton,
	ShowButton,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

// import ProductReferenceField from '../products/ProductReferenceField';
// import CustomerReferenceField from '../visitors/CustomerReferenceField';
import rowStyle from './rowStyle';
import UserField from '../../components/customField/UserField';
import SuspendButton from './SuspendButton';
import StatusField from '../../components/customField/StatusField';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    name: {
        maxWidth: '10em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const PostListActionToolbar = ({ children, ...props }) => {
	return (
		<div>
			{React.Children.map(children, (button) =>
				React.cloneElement(button, props)
			)}
		</div>
	);
};


const OfficeListDesktop = ({
    selectedRow,
    ...props
}) => {
    const classes = useListStyles();
    return (
		<Datagrid
			rowClick="show"
			rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell,
			}}
			optimized
			{...props}
		>
			<TextField source="reference" label="Reference Id" />
			<TextField
				source="hostName"
				sortable={false}
				cellClassName={classes.name}
			/>
			<TextField
				source="address"
				sortable={false}
				cellClassName={classes.comment}
			/>
			<TextField source="city" />
			<TextField source="postcode" />
			<TextField source="bookings" sortable={false} />
			<DateField source="registration" />
			<DateField source="latestBooking" />
			<NumberField
				source="totalEarning"
				options={{ style: "currency", currency: "AUD" }}
			/>
			{/* <TextField source="status" sortable={false} /> */}
			<StatusField source="status" label="Status" />
			<PostListActionToolbar>
				{/* <EditButton style={{ padding: 0, marginRight: 8 }} /> */}
				<SuspendButton style={{ padding: 0, marginRight: 8 }} />
				<ShowButton style={{ padding: 0 }} label="Reviews" />
			</PostListActionToolbar>
		</Datagrid>
	);
};

export default OfficeListDesktop;
