import { makeStyles } from "@material-ui/core/styles";
const createTitleChildBlock = {
  backgroundColor: "transparent",
  textAlign: "left",
  transition: "none",
  flex: "1",
  "&::before": {
    display: "none",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&::after": {
    display: "none",
  },
  "& input": {
    height: "35px",
    padding: 0,
    // backgroundColor: "#fcdfe1",
    border: '1px solid rgba(0,0,0,0.6)',
    boxSizing: "border-box",
    padding: "0 20px",
    transition: "background-color 0.3s",
  },
};
const createTitleChildLabel = {
  position: "static",
  transform: "none !important",
  width: "80px",
  color: "#353535",
};
const createFormStyle = {
  width: "1000px",
  margin: "0 auto",
};
const createInput = {
  backgroundColor: "transparent",
  border: "none",
  display: "flex",
  width: "100% !important",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "20px",
};
const createStyles = makeStyles({
  createFormStyle: createFormStyle,
  createTitle: {
    ...createInput,
    "& >div": createTitleChildBlock,
    "& >.MuiInputLabel-marginDense": createTitleChildLabel,
    "& >p": {
      position: "absolute",
      right: 0,
    },
  },
  createPageTitle: {
    
  },
  createPrice: {
    "& >div": {
      padding: "0",
      "& div": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        margin: "0 !important",
        left: "10px",
      },
      "& input": {
        boxSizing: "border-box",
        padding: "0 50px",
        textAlign: "left",
        lineHeight: "36px",
      },
    },
  },
  choiceTime: {
    display: "flex",
    width: "100%",
    "& >div": {
      flex: 1,
      "&:nth-child(2)": {
        marginLeft: "50px",
      },
      "& >label": {
        width: "auto",
      },
      "& >div": {
        "& input": {
          width: "100px",
          flex: "auto",
        },
      },
    },
  },
  createTextarea: {
    flexDirection: "column",
    alignItems: "flex-start",
    "& >div": {
      padding: "0 !important",
      marginTop: "10px",
      position: "relative",
      "& textarea": {
        // backgroundColor: "#fcdfe1",
    border: '1px solid rgba(0,0,0,0.6)',
    width: "100%",
        boxSizing: "border-box",
        padding: "15px",
        paddingRight: "25px",
      },
      "& >div": {
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translateY(-50%)",
      },
    },
  },
  createPageFile: {
    "& >label": {
      ...createTitleChildLabel,
    },
    "& >div": {
      "& >div": {
        // backgroundColor: "#fcdfe1",
    border: '1px solid rgba(0,0,0,0.6)',
  },
    },
  },
  optionPrice: {
    display: "flex",
    width: "100%",
    "& >div": {
      flex: 1,
      "&:nth-child(2)": {
        marginTop: "8px",
        marginBottom: "20px",
        "& >label": {
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
  },
  otherOptionBlock: {
    position: "relative",
    "& >label": {
      color: "#353535",
      fontSize: "1rem",
      lineHeight: "1.75",
      "& span": {
        fontSize: "1rem",
      },
    },
    "& >ul": {
      "& >li": {
        marginBottom: "30px",
        position: "relative",
        "& >div": {
          display: "none",
        },
        "& >span": {
          position: "absolute",
          top: "-20px",
          paddingTop: 0,
          height: "auto",
          right: 0,
          width: "120px",
          backgroundColor: "#000",
          textAlign: "center",
          "& button": {
            padding: 0,
            "& svg": {
              display: "none",
            },
            "& span": {
              color: "#e6e6e6",
            },
          },
        },
      },
    },
  },
  positionPriceGroup: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '8px',
  },
  positionPrice: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    "& >div": {
      margin: 0,
      width: '45% !important',
    }
  },
  price: {
    width: '100%',
    position: 'relative',
    "& >div": {
      margin: 0,
    }
  },
  select: {
    width: '120px !important' ,
    minWidth: 'auto',
    margin: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    height: '35px',
    "& >div": {
      "& >div": {
        height: '35px',
        padding: 0,
        lineHeight: '35px',
      }
    }
  }
});
const showStyles = makeStyles({
  createFormStyle: createFormStyle,
  commonInput: {
    "& >div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& >label": {
        width: "80px",
        color: "#353535",
        fontSize: "20px",
      },
      "& >div": {
        width: "auto",
      },
    },
  },
  commonInputFirst: {
    "& >div": {
      "& >label": {
        display: "none",
        fontWeight: "bold",
      },
      "& >div": {
        "& span": {
          fontWeight: "bold",
        },
      },
    },
  },
  time: {
    display: "flex",
    "& >label": {
      width: "80px",
    },
    "& >div": {},
  },
  description: {},
  otherOptions: {
    display: "flex",
  },
  otherOptionsFirst: {
    "& >div": {
      marginTop: "5px",
      "& >label": {
        display: "none",
        fontWeight: "bold",
      },
    },
  },
  leftCheckBox: {
    width: "60px",
  },
  rightContent: {
    "& >div": {
      marginTop: 0,
      paddingTop: "0 !important",
    },
  },
});
const customStyles = makeStyles({
  createFormStyle: {
    width: "1000px",
    margin: "0 auto",
    "& ul": {
      margin: 0,
      padding: 0,
      "& li": {
        listStyle: "none",
      },
    },
  },
  TextField: {
    marginBottom: "20px",
    "& >div": {
      display: "flex",
      "& >label": {
        fontSize: "1rem",
        width: "80px",
      },
    },
  },
  otherOptionsFirst: {
    "& >div": {
      "& >label": {
        display: "none",
      },
      "& >div": {
        fontWeight: "bold",
      },
    },
  },
  time: {
    display: "flex",
    "& >label": {
      width: "80px",
    },
    "& >div": {
      display: "flex",
      marginRight: '5px',
      "& >label": {
        marginRight: '3px',
      },
    },
  },
  Description: {
    "& >div": {
      display: "block",
    },
  },
  ImageField: {
    marginBottom: "20px",
    "& >img": {
      height: "150px",
      marginRight: '16px',
    },
  },
  FileField: {
    marginBottom: "20px",
    marginTop: "40px",
    "& >label": {
      display: "block",
      marginBottom: "10px",
    },
    "& >ul": {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      "& >li": {
        marginLeft: "20px",
        "& >a": {
          display: 'block',
          marginBottom:'5px',
          color: 'rgba(0, 0, 0, 0.87)'
        }
      },
    },
  },
  Stack: {
    alignItems: 'center',
    marginTop: '50px',
    "& button": {
      width: '500px',
      borderRadius: '0',
      backgroundColor: '#2f2f2f',
      height: '50px',
      "&:hover": {
        backgroundColor: '#2f2f2f',
      }
    }
  }
});
const CreateOptionStyles = makeStyles({
  createFormStyle: {
    width: '800px',
    margin: '0 auto',
  },
  LayoutTextField: {
    display:'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    position: 'relative',
  },
  TextField: {
    display: 'flex',
    flexDirection: 'row !important',
    alignItems: 'center',
    justifyContent: 'space-between',
    "& >label": {
      transition: 'none',
      transform: 'none !important',
      position: 'relative',
      color: 'rgba(0, 0, 0, 0.6) !important',
      width: '80px',
    },
    "& >div": {
      border: 'none',
      // backgroundColor: '#fae0e1',
      border: '1px solid rgba(0,0,0,0.6)',
      width: '300px',
      borderRadius: "0",
      "& fieldset": {
        border: 'none'
      }
    }
  },
  Select: {
    width: '120px',
    border: 'none',
    position: 'absolute !important',
    right: '0',
    "& >fieldset": {
      border: 'none',
    }
  },
  removeButton: {
    position: 'absolute !important',
    right: '-100px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  Stack: {
    alignItems: 'flex-end',
    marginTop: '20px',
  },
  Stack2: {
    alignItems: 'center',
    marginTop: '70px',
  },
  Button: {
    width: '200px',
  }
})
export { createStyles, showStyles, customStyles, CreateOptionStyles };
