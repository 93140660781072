import { get, isNil,find } from "lodash-es";
export const processPlatformSettings = (rawData) => {
    return {
		id: 1,
		DEFAULT_COMMISSION_RATE: find(
			rawData,
			(o) => o.code === "DEFAULT_COMMISSION_RATE"
		)?.value,
		DEFAULT_GST_RATE: find(rawData, (o) => o.code === "DEFAULT_GST_RATE")
			?.value,
		DEFAULT_PAYMENT_REMINDER_TIME: find(
			rawData,
			(o) => o.code === "DEFAULT_PAYMENT_REMINDER_TIME"
		)?.value,
		SYSTEM_MAINTAIN_FLAG: Boolean(
			find(rawData, (o) => o.code === "SYSTEM_MAINTAIN_FLAG")?.value
		),
		AUTO_APPROVE: Boolean(
			find(rawData, (o) => o.code === "AUTO_APPROVE")?.value
		),
	};
};
