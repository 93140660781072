import * as React from "react";
import {
	Identifier,
	Datagrid,
	DateField,
	TextField,
	DatagridProps,
	BooleanField,
	SelectField,
	NumberField,
	FunctionField,
	ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import UserField from "../../components/customField/UserField";
import {format,parseISO} from 'date-fns'
import {get} from 'lodash'
import rowStyle from "./rowStyle";
import StatusField from "../../components/customField/StatusField";


const useStyle = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid",
	},
	headerCell: {
		padding: "6px 8px 6px 8px",
	},
	rowCell: {
		padding: "6px 8px 6px 8px",
	},
	name: {
		maxWidth: "14em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

const BookingListDesktop = ({ selectedRow, ...props }) => {
	const classes = useStyle();
	return (
		<Datagrid
			rowClick="show"
			rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell,
			}}
			optimized
			{...props}
		>
			<DateField
				source="bookingInfo.createdAt"
				label="Date"
				sortable={false}
			/>
			<TextField
				source="bookingInfo.reference"
				label="Reference"
				sortable={false}
			/>
			<UserField source="userDetail" label="Client" sortable={false} />
			<ReferenceField
				label="Service"
				source="service"
				reference="services"
				sortable={false}
				link="show"
			>
				<TextField source="name" />
			</ReferenceField>
			<SelectField
				sortable={false}
				source="bookingInfo.pricingType"
				label="Type"
				choices={[
					{ id: "hour", name: "Hour" },
					{ id: "day", name: "Day" },
					{ id: "month", name: "Month" },
				]}
			/>
			<FunctionField
				source="bookingInfo.bookingDate.start"
				label="Start date time"
				sortable={false}
				render={(record) => {
					const time =
						get(record, "bookingInfo.bookingTime.start") ||
						get(record, "bookingInfo.bookingDate.start");
					const isHourBooking = get(
						record,
						"bookingInfo.isHourBooking",
						false
					);
					return format(
						new Date(time),
						isHourBooking ? "MM/dd/yyyy p" : "MM/dd/yyyy"
					);
				}}
			/>
			<FunctionField
				source="bookingInfo.bookingDate.start"
				label="End date time"
				sortable={false}
				render={(record) => {
					const time =
						get(record, "bookingInfo.bookingTime.end") ||
						get(record, "bookingInfo.bookingDate.end");
					const isHourBooking = get(
						record,
						"bookingInfo.isHourBooking",
						false
					);
					return format(
						new Date(time),
						isHourBooking ? "MM/dd/yyyy p" : "MM/dd/yyyy"
					);
				}}
			/>
			{/* <SelectField
				sortable={false}
				source="status"
				label="Status"
				choices={[
					{ id: "pending", name: "Pending" },
					{ id: "finished", name: "Finished" },
					{ id: "cancelled", name: "Cancelled" },
					{ id: "ongoing", name: "Ongoing" },
				]}
			/> */}
			<StatusField source="status" label="Status" />
		</Datagrid>
	);
};

export default BookingListDesktop;
