import { get, has } from "lodash";

const getUrlFileName =(url)=> url.substring(url.lastIndexOf("/") + 1);
const PreviewFile = ({
	record,
	source,
	preSaveSource = "url",
	target = "_blank",
	download,
	ping,
	rel,
}) => {

	const sourceValue = has(record, "rawFile")
		? get(record, source)
		: get(record, preSaveSource);

	const titleValue =
		get(record, 'title') || (has(record, "rawFile")
			? get(record, "rawFile.name")
			: getUrlFileName(sourceValue))
			
	return (
		<a
			href={sourceValue}
			title={titleValue}
			target={target}
			download={download}
			ping={ping}
			rel={rel}
		>
			{titleValue}
		</a>
	);
};
export default PreviewFile;