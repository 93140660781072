import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
} from "react-admin";

const HostEdit = (props) => (
	<Edit {...props}>
		<SimpleForm warnWhenUnsavedChanges >
			<TextInput source="id" disabled />
			<TextInput source="reference" />
			<TextInput source="hostName" />
			<SelectInput
				source="hostType"
				choices={[
					{ id: "Agency", name: "Agency" },
					{ id: "Company", name: "Company" },
				]}
			/>
			<TextInput source="contact" />
			<TextInput source="mobile" />
			<TextInput source="email" />
		</SimpleForm>
	</Edit>
);

export default HostEdit;
