import * as React from "react";
import {
	Identifier,
	Datagrid,
	DateField,
	TextField,
	DatagridProps,
	BooleanField,
	SelectField,
	NumberField,
	FunctionField,
	ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import UserField from "../../components/customField/UserField";
import {format,parseISO} from 'date-fns'
import {get} from 'lodash'


const useStyle = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid",
	},
	headerCell: {
		padding: "6px 8px 6px 8px",
	},
	rowCell: {
		padding: "6px 8px 6px 8px",
	},
	name: {
		maxWidth: "14em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

const ServiceFeeListDesktop = ({ selectedRow, ...props }) => {
	const classes = useStyle();
	return (
		<Datagrid
			rowClick="show"
			// rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell,
			}}
			optimized
			{...props}
		>
			<DateField source="createdAt" label="Date" sortable={false} />
			<TextField
				source="user"
				sortable={false}
				cellClassName={classes.name}
			/>
			{/* <ReferenceField
				label="User"
				source="user"
				reference="user"
				sortable={false}
				link="show"
			>
				<UserField source="userDetail" label="Client" sortable={false} />
			</ReferenceField> */}
			<TextField
				source="rate"
				sortable={false}
				cellClassName={classes.name}
			/>
		</Datagrid>
	);
};

export default ServiceFeeListDesktop;
