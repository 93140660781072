import BusinessIcon from "@material-ui/icons/Business";
import HostCreate from "./HostCreate";
import HostEdit from "./HostEdit";
import HostList from "./HostList";
import HostShow from "./HostShow";

export default {
	list: HostList,
	create: HostCreate,
	edit: HostEdit,
	show: HostShow,
	icon: BusinessIcon,
};
