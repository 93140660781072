import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	TextInput,
	ReferenceInput,
	SelectInput,
	SimpleList,
	useListContext,
	EditButton,
	ShowButton,
	CloneButton,
    NumberField,
    DateField
} from "react-admin";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";


const postFilters = [
	<TextInput source="q" label="Search" alwaysOn />,
	// <ReferenceInput source="userId" label="User" reference="users" allowEmpty>
	// 	<SelectInput optionText="name"/>
	// </ReferenceInput>,
];

const rowClick = (id, basePath, record) => {
	return "show";
};

const PostListActionToolbar = ({ children, ...props }) => {
	return (
		<div>
			{React.Children.map(children, (button) => React.cloneElement(button, props))}
		</div>
	);
};

const AdvertisementList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
	return (
		<List filters={postFilters} {...props}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.title}
					secondaryText={(record) => `${record.id}`}
					tertiaryText={(record) => record.price}
				/>
			) : (
				<Datagrid rowClick="edit" rowClick={rowClick}>
					<TextField source="id" label="Advertisement id" />
					<TextField source="title" />
					<NumberField
						source="price"
						options={{ style: "currency", currency: "AUD" }}
					/>
					<NumberField
						source="sale"
						options={{ style: "currency", currency: "AUD" }}
					/>
					<TextField source="stock" />
					<DateField source="startDate" />
                    <DateField source="endDate" />
					<PostListActionToolbar>
						<EditButton />
						<ShowButton />
					</PostListActionToolbar>
				</Datagrid>
			)}
		</List>
	);
}

export default AdvertisementList