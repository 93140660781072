import { get, find } from "lodash";
import { isNil } from "lodash-es";
import { processService } from "./service";
export const processOffice = (rawData, i18n=v=>v) => {
	const banners = get(rawData, ":resourceGroup.:resources", [])
		.filter((item) => item.category === "banner")
		.map((item) => ({
			...item,
			selected: Boolean(item.isDefault),
			uri: item.url,
		}));
	// const banners = [
	//     {
	//         uri: 'https://images.unsplash.com/photo-1638204325558-58ea17562125?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
	//         url: 'https://images.unsplash.com/photo-1638204325558-58ea17562125?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
	//         selected: true,
	//     },
	//     {
	//         uri: 'https://images.unsplash.com/photo-1638204325558-58ea17562125?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
	//         url: 'https://images.unsplash.com/photo-1638204325558-58ea17562125?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60',
	//         selected: false,
	//     },
	// ];

	const floorplans = get(rawData, ":resourceGroup.:resources", [])
		.filter((item) => item.category === "floorplan")
		.map((item) => ({
			...item,
			uri: item.url,
		}));

	// const videos = [
	// 	{
	// 		uri: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
	// 	},
	// 	{
	// 		uri: "https://media.w3.org/2010/05/sintel/trailer_hd.mp4",
	// 	},
	// ];
	const videos = get(rawData, ":resourceGroup.:resources", [])
		.filter((item) => item.category === "video")
		.map((item) => ({
			...item,
			uri: item.url,
		}));

	const banner =
		find(banners, {
			selected: true,
		}) || (banners.length > 0 ? banners[0] : null);

	let lowesPrice = Number.POSITIVE_INFINITY;
	let unit = "";
	get(rawData, ":assets", []).map((item) => {
		if ((item.perDay || Number.POSITIVE_INFINITY) < lowesPrice) {
			lowesPrice = item.perDay;
			unit = "P/Day";
		}
		if ((item.perHour || Number.POSITIVE_INFINITY) < lowesPrice) {
			lowesPrice = item.perHour;
			unit = "P/Hour";
		}
	});
    console.log(rawData)
	try {
		return {
			id: get(rawData, "id"),
			officeId: get(rawData, "id"),
			favor: Boolean(get(rawData, "isFavorite", false)),
			officeInfo: {
				reference: get(rawData, "reference"),
				status: get(rawData, "status"),
				banner: banner?.url || null,
				price: lowesPrice,
				priceUnit: unit,
				configuration: {
					hotdesk:
						find(
							get(rawData, ":assetCounts"),
							(o) => get(o, ":type.name") === "hotDesk"
						)?.count || 0,
					privateOffice:
						find(
							get(rawData, ":assetCounts"),
							(o) => get(o, ":type.name") === "privateRoom"
						)?.count || 0,
					meetingRoom:
						find(
							get(rawData, ":assetCounts"),
							(o) => get(o, ":type.name") === "meetingRoom"
						)?.count || 0,
				},
				coordinate: {
					latitude: get(rawData, ":address.latitude") || -33.925395,
					longitude: get(rawData, ":address.longitude") || 151.211366,
				},
				address: {
					line1: get(rawData, ":address.line1"),
					line2:
						get(rawData, ":address.line2", null) === null
							? `${get(rawData, ":address.city", "")} ${get(
									rawData,
									":address.state",
									""
							  )} ${get(rawData, ":address.postcode", "")}`
							: "",
					fullTextLine: get(rawData, ":address.fullText", ""),
				},
				rate: Number(get(rawData, "score"), 0),
				reviews: get(rawData, "reviewCount", 0),
				ratings: get(rawData, ":scores", []).map((item) => ({
					label: i18n(get(item, ":eType.name")),
					name: get(item, ":eType.name"),
					id: get(item, ":eType.id"),
					value: Number(item.score),
				})),
				availableNow: false,
				banners: [
					...(banner ? [banner] : []),
					...banners.filter((item) => item.id !== banner.id),
				],
				floorplans,
				videos,
				headline: get(rawData, "name", ""),
				description: get(rawData, "desc", ""),
				host: get(rawData, "user"),
				bookings: get(rawData, "bookingCount", 0),
				totalFavorate: get(rawData, "favoriteCount", 0),
				serviceActive: Boolean(get(rawData, "isActive")),
				gstExcluded: Boolean(get(rawData, "gstExcluded")),
				features: [
					...get(rawData, ":features", []).map((item) =>
						get(item, ":feature.name", "")
					),
					...JSON.parse(
						isNil(get(rawData, "userFeatures"))
							? "[]"
							: get(rawData, "userFeatures")
					),
				].map((item) => ({ name: item })),
				prices: [],
				contacts: [],
				services: get(rawData, ":assets", []).map(processService),
			},
		};
	} catch (error) {
		console.log(error);
	}
};
