import * as React from "react";
import jsonServerProvider from "ra-data-json-server";
import { Admin, Resource, ListGuesser, Layout } from "react-admin";
import user from './resources/user'
import host from './resources/host'
import adv from './resources/advertisement'
import advpap from './resources/advertisement/CreateOptions'
import reviews from "./resources/reviews";
import office from "./resources/office";
import services from "./resources/services";
import Dashboard from "./resources/dashboard";
import platformSettings from "./resources/platformSettings";
import bookings from "./resources/bookings";
import announcement from "./resources/announcement";
import serviceFee from "./resources/serviceFee";
import CustomMenu from './layout/CustomMenu'
import { APIS } from "./Apis";
import customRoutes from './routes/customRoutes';
// import {MyLayoust} from './layout'
// import Layout from './layout/layout'
// import CustomSideBar from "./components/CustomSideBar";

// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
const MyLayout = (props) => <Layout {...props} menu={CustomMenu} />;

const provider = new APIS()
const App = () => (
	<Admin
		layout={MyLayout}
		dataProvider={provider.getDataProvider()}
		dashboard={Dashboard}
		authProvider={provider.getAuthProvider()}
		customRoutes = {customRoutes}
	>
		<Resource name="client" options={{ label: "Client" }} {...user} />
		<Resource name="host" options={{ label: "Host" }} {...host} />
		<Resource
			name="advertisement"
			options={{ label: "Advertisement" }}
			{...adv}
		/>
		{/* <Resource
			name="advertisement/Price and promotion"
			options={{ label: "Advertisement/Price and promotion" }}
			list={advpap}
		/> */}
		<Resource name="reviews" options={{ label: "Reviews" }} {...reviews} />
		<Resource name="office" options={{ label: "Office" }} {...office} />
		<Resource
			name="serviceFee"
			options={{ label: "Service fee" }}
			{...serviceFee}
		/>
		<Resource
			name="services"
			options={{ label: "Services" }}
			{...services}
		/>
		<Resource
			name="bookings"
			options={{ label: "Bookings" }}
			{...bookings}
		/>
		<Resource
			name="announcement"
			options={{ label: "Announcement" }}
			{...announcement}
		/>
		<Resource
			name="system/platform-setting"
			options={{ label: "Platform-setting" }}
			{...platformSettings}
		/>
		{/* <Resource
			name="advertisement/submit"
			options={{ label: "submit" }}
			show = {Submit}
			/> */}
	</Admin>
);

export default App;
