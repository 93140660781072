import * as React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ThumbUp from "@material-ui/icons/ThumbUp";
import PanToolIcon from "@material-ui/icons/PanTool";
import { useTranslate, useUpdate, useNotify, useRedirect } from "react-admin";

/**
 * This custom button demonstrate using useUpdate to update data
 */
const SuspendButton = ({ record,...other }) => {
	const translate = useTranslate();
	const notify = useNotify();
	const redirectTo = useRedirect();
    // console.log(record);

	const [suspend, { loading }] = useUpdate(
		"office",
		record.id,
		{ status: "suspend" },
		record,
		{
			undoable: true,
			onSuccess: () => {
				notify(
					"Suspend success",
					"info",
					{},
					true
				);
				// redirectTo("/reviews");
			},
			onFailure: () => {
				notify(
					"Suspend failed",
					"warning"
				);
			},
		}
	);
	return record && record.status!=='suspend' ? (
		<Button
			color="primary"
			size="small"
			onClick={(e)=>{
				e.stopPropagation();
				suspend()
			}}
			disabled={loading}
			{...other}
		>
			<PanToolIcon
				color="primary"
				// size={12}
				style={{ paddingRight: "0.5em", fontSize: 16 }}
			/>
			Suspend
		</Button>
	) : (
		<span />
	);
};

SuspendButton.propTypes = {
	record: PropTypes.any,
};

export default SuspendButton;
