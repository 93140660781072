import * as React from "react";
import {
	Identifier,
	Datagrid,
	DateField,
	TextField,
	DatagridProps,
	BooleanField,
	SelectField,
	NumberField,
	FunctionField,
	ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import UserField from "../../components/customField/UserField";
import {format,parseISO} from 'date-fns'
import {get} from 'lodash'


const useStyle = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid",
	},
	headerCell: {
		padding: "6px 8px 6px 8px",
	},
	rowCell: {
		padding: "6px 8px 6px 8px",
	},
	name: {
		maxWidth: "14em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

const AnnouncementListDesktop = ({ selectedRow, ...props }) => {
	const classes = useStyle();
	return (
		<Datagrid
			rowClick="show"
			// rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell,
			}}
			optimized
			{...props}
		>
			<DateField source="createdAt" label="Date" sortable={false} />
			<DateField
				source="expiryDate"
				label="Expiry date"
				sortable={false}
			/>
			<TextField
				source="title"
				sortable={false}
				cellClassName={classes.name}
			/>
			<BooleanField source="isTop" label="Is top" sortable={false} />
			<SelectField
				sortable={false}
				source="type"
				label="Type"
				choices={[
					{ id: "host", name: "Host" },
					{ id: "platform", name: "Platform" },
				]}
			/>
		</Datagrid>
	);
};

export default AnnouncementListDesktop;
