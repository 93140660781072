import * as React from 'react';
import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    SearchInput,
    SelectInput,
    TextInput
} from 'react-admin';

const officeFilters = [
	// <SearchInput source="q" alwysOn />,
	<TextInput source="reference" />,
	<TextInput source="hostName" />,
	<TextInput source="address" />,
	<TextInput source="city" />,
	<TextInput source="postcode" />,
	<SelectInput
		source="status"
		choices={[
			{ id: "verified", name: "Verified" },
			{ id: "pending", name: "Pending" },
			{ id: "rejected", name: "Rejected" },
			{ id: "suspend", name: "Suspend" },
		]}
	/>,
	// <ReferenceInput source="customer_id" reference="customers">
	//     <AutocompleteInput
	//         optionText={(choice) =>
	//             choice?.id // the empty choice is { id: '' }
	//                 ? `${choice.first_name} ${choice.last_name}`
	//                 : ''
	//         }
	//     />
	// </ReferenceInput>,
	// <ReferenceInput source="product_id" reference="products">
	//     <AutocompleteInput optionText="reference" />
	// </ReferenceInput>,
	// <DateInput source="date_gte" />,
	// <DateInput source="date_lte" />,
];

export default officeFilters;
