import { get } from "lodash"

export const processAnnouncement = (rawData)=>{
    return {
		id: get(rawData, "id"),
		title: get(rawData, "title"),
		type: get(rawData, "type"),
		isTop: Boolean(get(rawData, "isTop")),
		content: get(rawData, "content"),
		resources: get(rawData, "resources"),
		createdAt: get(rawData, "createdAt"),
		expiryDate: get(rawData, "expiryDate"),
	};
}