import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	BooleanInput,
	DateInput,
	Create,
	TabbedForm,
	Tab,
	FormTab,
	required,
	NumberInput,
	AutocompleteInput,
} from "react-admin";

const ServiceFeeCreate = (props) => (
	<Create {...props}>
		<SimpleForm sanitizeEmptyValues={false} redirect="list">
			<ReferenceInput
				label="Host"
				source="user"
				reference="host"
				validate={[required()]}
			>
				<SelectInput
					optionText="hostName"
					validate={[required()]}
				/>
			</ReferenceInput>
			<NumberInput
				source="rate"
				resettable
				validate={[required()]}
				min={0}
				max={1}
				// format={(v) => v * 100}
				// parse={(v) => parseFloat(v) / 100}
			/>
		</SimpleForm>
	</Create>
);

export default ServiceFeeCreate;
