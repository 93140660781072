import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	BooleanField,
	TextInput,
	ReferenceInput,
	SelectInput,
	ShowGuesser,
	SimpleShowLayout,
	Show,
	DateField,
	ReferenceManyField,
	NumberField,
	FunctionField,
	SingleFieldList,
	ChipField,
	ArrayField,
	TabbedShowLayout,
	Tab,
	ImageField,
	Pagination,
} from "react-admin";
import get from 'lodash/get'
import ImageLighHouseField from "../../components/customField/ImageLighHouseField";
import VideosField from "../../components/customField/VideosField";
import ReviewListDesktop from "../reviews/ReviewListDesktop";
import ServiceListDesktop from "../services/ServiceListDesktop";
import { Route, RouteChildrenProps, useHistory } from "react-router-dom";

const OfficeShow = (props) => {

    const history = useHistory();
	return <Show {...props}>
		<TabbedShowLayout>
			<Tab label="Detail">
				<TextField source="id" />
				<TextField source="officeInfo.reference" label="Reference" />
				<TextField source="officeInfo.headline" label="Title" />
				<TextField source="officeInfo.status" label="Status" />
				<TextField
					source="officeInfo.description"
					label="Description"
				/>
				<TextField
					source="officeInfo.address.line1"
					label="Address line1"
				/>
				<TextField
					source="officeInfo.address.line2"
					label="Address line2"
				/>
				<FunctionField
					label="Coordinate"
					render={(record) => {
						console.log(321, record);
						return `Lat:${get(
							record,
							"officeInfo.coordinate.latitude"
						)}; Long:${get(
							record,
							"officeInfo.coordinate.longitude"
						)}`;
					}}
				/>
				<ArrayField source="officeInfo.features" label="Features">
					<SingleFieldList linkType={null}>
						<ChipField source="name" />
					</SingleFieldList>
				</ArrayField>
				<BooleanField
					source="officeInfo.serviceActive"
					label="Service actived"
				/>
				<BooleanField
					source="officeInfo.gstExcluded"
					label="Gst excluded"
				/>
			</Tab>
			<Tab label="Statistics">
				<NumberField source="officeInfo.rate" label="Rate" />
				<ArrayField source="officeInfo.ratings" label="Rating detail">
					<Datagrid>
						<TextField source="name" />
						<TextField source="value" />
					</Datagrid>
				</ArrayField>
				<NumberField
					source="officeInfo.reviews"
					label="Reviews number"
				/>
				<NumberField
					source="officeInfo.bookings"
					label="Bookings number"
				/>
				<NumberField
					source="officeInfo.totalFavorate"
					label="TotalFavorate number"
				/>
				<NumberField
					source="officeInfo.totalFavorate"
					label="TotalFavorate number"
				/>
			</Tab>
			<Tab label="Resources">
				<ImageField
					source="officeInfo.banner"
					label="Display banner"
					target="_blank"
				/>
				<ImageLighHouseField
					source="officeInfo.banners"
					src="uri"
					label="Banners"
				/>
				<ImageLighHouseField
					source="officeInfo.floorplans"
					src="uri"
					label="Floorplans"
				/>
				<VideosField
					source="officeInfo.videos"
					src="uri"
					label="Videos"
				/>
			</Tab>
			<Tab label="Services">
				<div style={{ paddingTop: 12, paddingBottom: 12 }}>
					<ArrayField source="officeInfo.services" label="">
						<ServiceListDesktop rowClick={(data, basePath, record) => {
                            return `/services/${record.id}/show`;
                        }}/>
					</ArrayField>
				</div>
			</Tab>
			<Tab label="Reviews">
				<div style={{ paddingTop: 12, paddingBottom: 12 }}>
					<ReferenceManyField
						label=""
						reference="reviews"
						source="officeId"
						target="officeId"
						emptyText="no available data"
						pagination={<Pagination />}
					>
						<ReviewListDesktop />
					</ReferenceManyField>
				</div>
			</Tab>
		</TabbedShowLayout>
	</Show>
}

export default OfficeShow;
