import { get } from "lodash";
import { processComment } from "../data/comment";


export const reviewApi = (httpClient) => {
    const updateOne_ = async (id,data)=>{
        const { result } = await httpClient.put(
            ["admin", "comment", id],
            data
        );
        return id
    }
    const getOne_ = async (id)=>{
        const { result } = await httpClient.get(
            ["admin", "comment", id],
        );
        return result;
    }
    const delOne_ = async (id)=>{
        const { result } = await httpClient.del(
            ["admin", "comment", id],
        );
        return result;
    }

    const getList_ = async (params)=>{
        const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const { status } = params.filter;
        const { target, id } = params;
        const query = {
			pageSize: perPage,
			pageIndex: page - 1,
			status,
		};
        if(target && id){
            query[target] = id
        }
		const { result } = await httpClient.get(["admin", "comment"], query);

		return {
			data: result.items.map(processComment),
			total: result.totalItems,
		};
    }
    
    return {
        getList: async (params) => {
            return await getList_(params)
        },

        getOne: async (params) => {
            const result = await getOne_(params.id)
            console.log(result)
            return {
                data:processComment(result)
            }
        },

        getMany: async (params) => {
            const res = await Promise.all(
                params.ids.map((id) => getOne_(id))
            );
            console.log(res)
            return {
                data: res.map(processComment),
            }; 
        },

        getManyReference: async (params) => {
            console.log('r',params)
            return await getList_(params);
        },

        update: async (params) => {
            await httpClient.put(
				["admin", "comment", params.id],
				{
					status: get(params, "data.status"),
				}
			);
            return {
				data: processComment({
					...params.previousData.rawData,
                    ...params.data
				}),
			};
        },

        updateMany: async (params) => {
            const res = await Promise.all(
				params.ids.map((id) => updateOne_(id, params.data))
			);
            return {
				data: res.map(processComment),
			};
        },

        create: (resource, params) => {
            // console.log(params);
            // const mockData = mockDataMaper[resource] || [];
            // return new Promise((resolve, reject) =>
            // 	setTimeout(() => {
            // 		resolve({
            // 			data: {
            // 				...params.data,
            // 				id: Math.random(),
            // 			},
            // 		});
            // 	}, 1000)
            // );
            // httpClient(`${apiUrl}/${resource}`, {
            // 	method: "POST",
            // 	body: JSON.stringify(params.data),
            // }).then(({ json }) => ({
            // 	data: { ...params.data, id: json.id },
            // })),
        },

        delete: async (params) => {
            const res = await delOne_(params.id)
            return {data:{id:params.id}}
        },

        deleteMany: async (params) => {
            const res = await Promise.all(
				params.ids.map((id) => updateOne_(id, params.data))
			);
            return { data: params.ids };
        },
    }
};