import { get } from "lodash-es";
import { processUser } from "./user";

export const processHost = (rawData) => {
	return {
		// ...rawData,
		id: get(rawData, "id"),
		reference: get(rawData, "reference"),
		hostName: get(rawData, "name"),
		hostType: get(rawData, "hostType"),
		contact: null,
		mobile: get(rawData, "mobile"),
		email: get(rawData, "email"),
		bookings: get(rawData, "Bookings"),
		registration: get(rawData, "createdAt"),
		lastestBooking: get(rawData, "latestBooking"),
		totalEarning: Number(get(rawData, "totalEaring")),
		requests: get(rawData, "Requests"),
		rawData: rawData,
	};
};
