import {
	List,
	Datagrid,
	TextField,
	EmailField,
	TextInput,
	ReferenceInput,
	SelectInput,
	ShowGuesser,
	SimpleShowLayout,
	Show,
	DateField,
	ListBase,
	ShowBase,
	NumberField,
	Title,
	useListContext,
	EditButton,
} from "react-admin";
import React from 'react'
import { Paper,CircularProgress } from "@material-ui/core";
import { makeStyles, StylesContext } from "@material-ui/styles";
import get from 'lodash/get'

const ListContent = (props)=>{
    const {} = props
    const { data, ids, loading, basePath } = useListContext();
    const classes = useStyles();
    // console.log(data, ids, basePath);
    const configData = data[1]
    const lines = [
		{
			label: "Auto approve",
			value: get(configData, "AUTO_APPROVE") ? "Enabled" : "Disabled",
		},
		{
			label: "Payment reminder time",
			value: `${get(configData, "DEFAULT_PAYMENT_REMINDER_TIME")} day`,
		},
		{
			label: "GST rate",
			value: `${get(configData, "DEFAULT_GST_RATE")}`,
		},
		{
			label: "Default commission rate",
			value: `${get(configData, "DEFAULT_COMMISSION_RATE")}`,
		},
		// {
		// 	label: "System down",
		// 	value: `${
		// 		get(configData, "SYSTEM_MAINTAIN_FLAG") ? "Yes" : "False"
		// 	}`,
		// },
	];
    return (
		<div>
			{loading ? (
				<CircularProgress size={20} color="primary" />
			) : (
				<div>
                    <div className={classes.con}>
                        <EditButton basePath={basePath} record={configData} />
                    </div>
					{lines.map((item, index) => (
						<div className={classes.alignH} key={index}>
							<div className={classes.label}>{item.label}</div>
							<div className={classes.value}>{item.value}</div>
						</div>
					))}
				</div>
			)}
		</div>
	);

}

function PlatformSettingsList(props) {
    const classes = useStyles()
    return (
		<ListBase {...props}>
			<Title title={"Platform settings"} />
			<Paper className={classes.paper}>
				<ListContent/>
			</Paper>
		</ListBase>
	);
}

export default PlatformSettingsList

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 40,
	},
	paper: {
		marginTop: 12,
		padding: 24,
	},
	alignH: {
		alignItems: "center",
        display:"flex",
        marginBottom:8
	},
	label: {
		width: "30%",
	},
	value: {
		width: "30%",
	},
    con:{
        justifyContent:"flex-end",
        display:"flex"
    }
}));
