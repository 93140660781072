import * as React from 'react';
import {
	useEditController,
	EditContextProvider,
	useTranslate,
	TextInput,
	SimpleForm,
	DateField,
	EditProps,
	TextField,
} from "react-admin";
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// import ProductReferenceField from '../products/ProductReferenceField';
// import CustomerReferenceField from '../visitors/CustomerReferenceField';
import StarRatingField from './StarRatingField';
import ReviewEditToolbar from './ReviewEditToolbar';
import UserField from '../../components/customField/UserField';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
}));


const ReviewEdit = ({ onCancel, ...props }) => {
    const classes = useStyles();
    const controllerProps = useEditController(props);
    const translate = useTranslate();
    if (!controllerProps.record) {
        return null;
    }
    const record = controllerProps.record;
    return (
		<div className={classes.root}>
			<div className={classes.title}>
				<Typography variant="h6">
					{/* {translate('resources.reviews.detail')} */}
					Detail
				</Typography>
				<IconButton onClick={onCancel}>
					<CloseIcon />
				</IconButton>
			</div>
			<EditContextProvider value={controllerProps}>
				<SimpleForm
					className={classes.form}
					basePath={controllerProps.basePath}
					record={controllerProps.record}
					save={controllerProps.save}
					version={controllerProps.version}
					redirect="list"
					resource="reviews"
					toolbar={<ReviewEditToolbar />}
				>
					<UserField
						source="userDetail"
						label="User"
						formClassName={classes.inlineField}
					/>
					<DateField
						source="createdAt"
						label="Date"
						formClassName={classes.inlineField}
					/>
					{/* <StarRatingField formClassName={classes.inlineField} /> */}
					<TextField
						source="rating"
						formClassName={classes.inlineField}
					/>
					<TextField
						source="status"
						formClassName={classes.inlineField}
					/>
					<TextInput
						source="comment"
						rowsMax={15}
						multiline
						fullWidth
						disabled
					/>
				</SimpleForm>
			</EditContextProvider>
		</div>
	);
};

export default ReviewEdit;
