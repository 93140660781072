import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext, Labeled } from "react-admin";
import { get } from "lodash";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const UserField = (props) => {
	const { source, src = "url" } = props;
	const record = useRecordContext(props);
	const data = get(record,source)
    // console.log(data)
	return (
		<span style={styles.line}>
			<img src={get(data,'avatar')} style={styles.image}/>
            <span>{get(data,'name')}</span>
		</span>
	);
};

UserField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};
UserField.defaultProps = {
	addLabel: true,
};

const styles = {
	image: {
        height:22,
        width:22,
        borderRadius:40,
        objectFit:"contain",
        marginRight:8
	},
    line:{
        alignItems:'center',
        display:'flex'
    }
};

export default UserField;
