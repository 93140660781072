import { makeStyles } from "@material-ui/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	BooleanInput,
	NumberInput,
	SaveButton,
} from "react-admin";
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		justifyContent: "space-between",
	},
}));

const Toolbar = (props)=>{
    const {
        basePath,
        handleSubmitWithRedirect,
        invalid,
        record,
        resource,
        saving,
    } = props;
    const classes = useStyles();

    if (!record) return null;
    return <MuiToolbar className={classes.root}>
		<SaveButton {...props}/>
	</MuiToolbar>;
}
const HostEditPlatformSettingsEdit = (props) => (
	<Edit {...props} title="Platform-setting edit">
		<SimpleForm warnWhenUnsavedChangesd toolbar={<Toolbar />} >
			<BooleanInput source="AUTO_APPROVE" label="Auto approve" />
			<NumberInput
				source="DEFAULT_PAYMENT_REMINDER_TIME"
				label="Payment reminder time"
				min={0}
			/>
			<NumberInput source="DEFAULT_GST_RATE" label="GST rate" min={0} />
			<NumberInput
				source="DEFAULT_COMMISSION_RATE"
				label="Default commission rate"
				min={0}
			/>
		</SimpleForm>
	</Edit>
);

export default HostEditPlatformSettingsEdit;
