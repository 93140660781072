import * as React from "react";
import {
  List,
  Datagrid,
  EmailField,
  TextInput,
  ReferenceInput,
  SelectInput,
  ShowGuesser,
  SimpleShowLayout,
  Show,
  DateField,
  NumberField,
  // ImageField,
  // FileField,
} from "react-admin";
import ImageLighHouseField from "../../components/customField/ImageLighHouseField";
import { Button, Stack  } from "@mui/material";
import { customStyles } from "./advertisementStyle";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { Title } from "react-admin";
import TextField from "../../components/customField/TextField";
import ImageField from '../../components/customField/ImageField'
import FileField from '../../components/customField/FileField'
const Submit = (props) => {
  const classes = customStyles()
  const [files] = React.useState([
    {
      url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      title: "test file",
    },
    {
      url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      title: "test file",
    },
    {
      url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
      title: "test file",
    },
  ])
  const [images] = React.useState([
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG1hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG1hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG1hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
    "https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fG1hbnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80",
  ])
  return (
    <Card>
      <Title title="Not Found" />
      <CardContent className={classes.createFormStyle}>
        <TextField
          type="Title"
          value={props.title}
          className={`${classes.TextField} ${classes.otherOptionsFirst}`}
        />
        <TextField
          type="Price"
          value={100000}
          className={`${classes.TextField}`}
        />
        <div className={classes.time}>
          <label>Date</label>
          <div>
            <label>From: </label>
            <TextField
              type="StartDate"
              label=""
              value='N/A'
              className={`${classes.TextField}`}
            />
          </div>
          <div>
            <label>To: </label>
            <TextField
              type="EndDate"
              label=""
              value="30/10/2021"
              className={`${classes.TextField}`}
            />
          </div>
        </div>
        <TextField
          type="Description"
          label=''
          value="test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description test description "
          className={`${classes.TextField} ${classes.Description}`}
        />
        <ImageField className={classes.ImageField} images={images}  />
        <FileField className={classes.FileField} files={files} label='Documents' />
        <Stack className={classes.Stack}>
          <Button variant="contained" className={classes.Button}>Submit</Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default Submit;
