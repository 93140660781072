import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	TextInput,
	ReferenceInput,
	SelectInput,
	ShowGuesser,
	SimpleShowLayout,
	Show,
	DateField,
	NumberField,
} from "react-admin";


const UserShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="reference" />
			<TextField source="clientName" />
			<TextField source="clientType" />
			<TextField source="contact" />
			<TextField source="mobile" />
			<EmailField source="email" />
			<TextField source="bookings" />
			<DateField source="registration" />
			<DateField source="lastestBooking" />
			<NumberField
				source="totalSpending"
				options={{ style: "currency", currency: "AUD" }}
			/>
			<TextField source="requests" />
		</SimpleShowLayout>
	</Show>
);

export default UserShow;