import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmojiFoodBeverageIcon from "@material-ui/icons/EmojiFoodBeverage";
import EventIcon from "@material-ui/icons/Event";
import RateReviewIcon from "@material-ui/icons/RateReview";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import TuneIcon from "@material-ui/icons/Tune";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import VolumeUp from "@material-ui/icons/VolumeUp";
import SettingsIcon from "@material-ui/icons/Settings";
import MoneyIcon from "@material-ui/icons/Money";
export default [
	{
		name: "client",
		path: "/",
		label: "Dashboard",
		icon: DashboardIcon,
	},
	{
		name: "client",
		path: "/client",
		label: "Client",
		icon: PersonIcon,
	},
	{
		name: "host",
		path: "/host",
		label: "Host",
		icon: BusinessIcon,
	},
	{
		name: "office",
		path: "/office",
		label: "Office",
		icon: EmojiFoodBeverageIcon,
	},
	{
		name: "services",
		path: "/services",
		label: "Services",
		icon: RoomServiceIcon,
	},
	{
		name: "bookings",
		path: "/bookings",
		label: "Bookings",
		icon: EventIcon,
	},
	{
		name: "reviews",
		path: "/reviews",
		label: "Reviews",
		icon: RateReviewIcon,
	},
	{
		name: "announcement",
		path: "/announcement",
		label: "Announcement",
		icon: VolumeUp,
	},
	{
		name: "serviceFee",
		path: "/serviceFee",
		label: "Service fee",
		icon: MoneyIcon,
	},
	{
		name: "request",
		path: "/request",
		label: "Request",
		icon: EmojiPeopleIcon,
	},
	{
		name: "finance",
		path: "/finance",
		label: "Finance",
		icon: AttachMoneyIcon,
		subMenu: [
			{
				name: "Finance setting",
				path: "finance-setting",
				label: "Finance setting",
			},
			{
				name: "Account receivable",
				path: "account-receivable",
				label: "Account receivable",
			},
			{
				name: "Account payable",
				path: "account-payable",
				label: "Account payable",
			},
			{
				name: "Cash management",
				path: "cash-management",
				label: "Cash management",
			},
			{
				name: "Finance Report",
				path: "finance-report",
				label: "Finance Report",
			},
		],
	},
	{
		name: "advertisement",
		path: "/advertisement",
		label: "Advertisement",
		icon: FeaturedVideoIcon,
		subMenu: [
			{
				name: "Advertisement",
				path: "",
				label: "Advertisement",
			},
			{
				name: "price and promotion",
				path: "price and promotion",
				label: "Price and promotion",
			},
			{
				name: "On-going ADs",
				path: "on-going-ads",
				label: "On-going ADs",
			},
			{
				name: "Submit",
				path: "submit",
				label: "Submit",
				icon: SettingsIcon,
			},
			{
				name: "Close",
				path: "close",
				label: "Close",
				icon: SettingsIcon,
			},
		],
	},
	{
		name: "system",
		path: "/system",
		label: "System admin",
		icon: SettingsIcon,
		subMenu: [
			{
				name: "platform setting",
				path: "platform-setting",
				label: "Platform-setting",
			},
			{
				name: "User management",
				path: "user-management",
				label: "User management",
			},
			{
				name: "Roles and access control",
				path: "roles-and-access-control",
				label: "Roles and access control",
			},
			{
				name: "System monitoring",
				path: "system-monitoring",
				label: "System monitoring",
			},
			{
				name: "System alert",
				path: "system-alert",
				label: "System alert",
			},
			{
				name: "Management report",
				path: "management-report",
				label: "Management report",
			},
		],
	},
];
