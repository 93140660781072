import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	BooleanField,
	TextInput,
	ReferenceInput,
	SelectInput,
	ShowGuesser,
	SelectField,
	SimpleShowLayout,
	Show,
	DateField,
	ReferenceManyField,
	NumberField,
	FunctionField,
	SingleFieldList,
	ChipField,
	ArrayField,
	TabbedShowLayout,
	Tab,
	ImageField,
	Pagination,
	ReferenceManyFieldd,
} from "react-admin";
import BookingListDesktop from "../bookings/BookingListDesktop";


const ServiceShow = (props) => (
	<Show {...props}>
		<TabbedShowLayout>
			<Tab label="Detail">
				<TextField source="id" />
				<TextField source="name" />
				<DateField source="createdAt" label="Date" />
				<SelectField
					source="type"
					choices={[
						{ id: "privateRoom", name: "Private room" },
						{ id: "hotDesk", name: "Hot desk" },
						{ id: "meetingRoom", name: "Meeting room" },
						{ id: "parking", name: "Parking" },
					]}
				/>
				<TextField source="capacity" />
				<NumberField
					source="perHour"
					options={{ style: "currency", currency: "AUD" }}
				/>
				<NumberField
					source="perDay"
					options={{ style: "currency", currency: "AUD" }}
				/>
				<NumberField
					source="perMonth"
					options={{ style: "currency", currency: "AUD" }}
				/>
				a
				<NumberField
					source="cancelPercentage"
					options={{ style: "percent" }}
				/>
				<BooleanField source="active" />
				<ArrayField source="features" label="Features">
					<SingleFieldList linkType={null}>
						<ChipField source="name" />
					</SingleFieldList>
				</ArrayField>
			</Tab>
			<Tab label="Bookings">
				<div style={{ paddingTop: 12, paddingBottom: 12 }}>
					<ReferenceManyField
						label=""
						reference="bookings"
						source="id"
						target="asset"
						emptyText="no available data"
						pagination={<Pagination />}
					>
						<BookingListDesktop />
					</ReferenceManyField>
				</div>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default ServiceShow;
