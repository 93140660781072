import { find, get, has, random, sortBy } from "lodash";
import { stringify } from "query-string";
import { client } from "./mock/client";
import { advertisement } from "./mock/advertisement";
import { host } from "./mock/host";
import { reviews } from "./mock/reviews";
import { reviewApi } from "./apis/reviews";
import { platformSettings } from "./mock/platformSettings";
import { officeApi } from "./apis/office";
import { systemSettingApi } from "./apis/systemSetting";
import { serviceApi } from "./apis/services";
import { bookingsApi } from "./apis/bookings";
import { announcementApi } from "./apis/announcement";
import { hostApi } from "./apis/host";
import { serviceFeeApi } from "./apis/serviceFee";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

const mockDataMaper = {
	client,
	host,
	advertisement,
	reviews,
	"system/platform-setting": platformSettings,
};


function paginate(array, page_size, page_number) {
	// human-readable page numbers usually start with 1, so we reduce 1 in the first argument
	return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export default (httpClient) => {
	const apiMaper = {
		reviews: reviewApi(httpClient),
		office: officeApi(httpClient),
		"system/platform-setting": systemSettingApi(httpClient),
		services: serviceApi(httpClient),
		bookings: bookingsApi(httpClient),
		announcement: announcementApi(httpClient),
		host: hostApi(httpClient),
		serviceFee:serviceFeeApi(httpClient)
	};
	return {
		getList: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].getList(params);
			} else {
				const { page, perPage } = params.pagination;
				const { field, order } = params.sort;
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						const sorted = field
							? sortBy(mockData, field)
							: mockData;
						resolve({
							data: paginate(sorted, perPage, page),
							total: sorted.length,
						});
					}, 1000)
				);
			}
		},

		getOne: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].getOne(params);
			} else {
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						resolve({
							data: find(mockData, (o) => o.id === Number(params.id)),
						});
					}, 1000)
				);
			}
		},

		getMany: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].getMany(params);
			} else {
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						resolve({
							data: mockData.filter((o) =>
								params.ids.includes(Number(o.id))
							),
						});
					}, 1000)
				);
			}
		},

		getManyReference: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].getManyReference(params);
			} else {
				const { page, perPage } = params.pagination;
				const { field, order } = params.sort;
	
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						const sorted = field ? sortBy(mockData, field) : mockData;
						const sortedAndReferenced = sorted.filter(
							(o) => o[params.target] === Number(params.id)
						);
						resolve({
							data: paginate(sortedAndReferenced, perPage, page),
							total: Math.ceil(sortedAndReferenced.length / perPage),
						});
					}, 1000)
				);
			}

			// const query = {
			// 	...fetchUtils.flattenObject(params.filter),
			// 	[params.target]: params.id,
			// 	_sort: field,
			// 	_order: order,
			// 	_start: (page - 1) * perPage,
			// 	_end: page * perPage,
			// };
			// const url = `${apiUrl}/${resource}?${stringify(query)}`;

			// return httpClient(url).then(({ headers, json }) => {
			// 	if (!headers.has("x-total-count")) {
			// 		throw new Error(
			// 			"The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
			// 		);
			// 	}
			// 	return {
			// 		data: json,
			// 		total: parseInt(
			// 			headers.get("x-total-count").split("/").pop(),
			// 			10
			// 		),
			// 	};
			// });
		},

		update: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].update(params);
			} else {
				console.log("update", resource, params);
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						resolve({
							data: find(mockData, (o) => o.id === Number(params.id)),
						});
					}, 1000)
				);
			}
		},

		updateMany: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].updateMany(params);
			} else {
				const mockData = mockDataMaper[resource] || [];
				return Promise.all(
					params.ids.map(
						(id) =>
							new Promise((resolve, reject) =>
								setTimeout(() => {
									resolve({
										data: find(
											mockData,
											(o) => o.id === Number(params.id)
										),
									});
								}, 1000)
							)
					)
				).then((responses) => ({
					data: responses.map(({ json }) => json.id),
				}));
			}
		},

		create: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].create(params);
			} else {
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						resolve({
							data: {
								...params.data,
								id: Math.random(),
							},
						});
					}, 1000)
				);
			}
			// httpClient(`${apiUrl}/${resource}`, {
			// 	method: "POST",
			// 	body: JSON.stringify(params.data),
			// }).then(({ json }) => ({
			// 	data: { ...params.data, id: json.id },
			// })),
		},

		delete: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].delete(params);
			} else {
				const mockData = mockDataMaper[resource] || [];
				return new Promise((resolve, reject) =>
					setTimeout(() => {
						resolve({
							data: find(mockData, (o) => o.id === Number(params.id)),
						});
					}, 1000)
				);
			}
		},

		deleteMany: async (resource, params) => {
			if (has(apiMaper, resource)) {
				return await apiMaper[resource].deleteMany(params);
			} else {
				const mockData = mockDataMaper[resource] || [];
				return Promise.all(
					params.ids.map(
						(id) =>
							new Promise((resolve, reject) =>
								setTimeout(() => {
									resolve({
										data: find(
											mockData,
											(o) => o.id === Number(params.id)
										),
									});
								}, 1000)
							)
					)
				).then((responses) => ({
					data: responses.map(({ json }) => json.id),
				}));
			}
		},
	};
}
