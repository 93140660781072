import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
	EditButton,
	Edit,
	SimpleForm,
	ReferenceInput,
	SelectInput,
	TextInput,
	BooleanInput,
	DateInput,
	TabbedForm,
	FormTab,
	required,
	NumberInput,
} from "react-admin";

const ServiceFeeEdit = (props) => (
	<Edit {...props}>
		<SimpleForm
			warnWhenUnsavedChanges
			sanitizeEmptyValues={false}
			redirect="list"
		>
			<ReferenceInput
				label="Host"
				source="user"
				reference="host"
				validate={[required()]}
			>
				<SelectInput optionText="hostName" validate={[required()]} />
			</ReferenceInput>
			<NumberInput
				source="rate"
				resettable
				validate={[required()]}
				min={0}
				max={1}
			/>
		</SimpleForm>
	</Edit>
);

export default ServiceFeeEdit;
