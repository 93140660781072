import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	BooleanField,
	TextInput,
	ReferenceInput,
	SelectInput,
	ShowGuesser,
	SelectField,
	SimpleShowLayout,
	Show,
	DateField,
	ReferenceManyField,
	NumberField,
	FunctionField,
	SingleFieldList,
	ChipField,
	ArrayField,
	TabbedShowLayout,
	Tab,
	ImageField,
	Pagination,
} from "react-admin";
import { format, parseISO } from "date-fns";
import { get } from "lodash";
import UserField from "../../components/customField/UserField";

const AnnouncementShow = (props) => (
	<Show {...props}>
		<TabbedShowLayout>
			<Tab label="Detail">
				<TextField source="id" />
				<DateField source="createdAt" label="Date" sortable={false} />
				<DateField
					source="expiryDate"
					label="Expiry date"
					sortable={false}
				/>
				<TextField
					source="title"
					sortable={false}
				/>
				<TextField
					source="content"
					sortable={false}
				/>
				<BooleanField source="isTop" label="Is top" sortable={false} />
				<SelectField
					sortable={false}
					source="type"
					label="Type"
					choices={[
						{ id: "host", name: "Host" },
						{ id: "platform", name: "Platform" },
					]}
				/>
			</Tab>
			<Tab label="Resource">

			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default AnnouncementShow;
