import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext, Labeled } from "react-admin";
import { get, isArray } from "lodash";
import { Grid } from "@material-ui/core";

import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";

const StatusField = (props) => {
	const { source,settings} = props;
	const record = useRecordContext(props);
	const data = get(record, source);
	return (
		<div>
			<span style={settings[data]?.style}>{settings[data]?.label}</span>
		</div>
	);
};

StatusField.propTypes = {
	label: PropTypes.string,
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};
StatusField.defaultProps = {
	addLabel: true,
	settings: {
		pending: {
			style: { color: orange[500]},
			label: "Pending",
		},
		finished: {
			style: { color: green[500] },
			label: "Finished",
		},
		verified: {
			style: { color: green[500] },
			label: "Verified",
		},
		ongoing: {
			style: {
				color: blue[500],
			},
			label: "Ongoing",
		},
		rejected: {
			style: { color: red[500] },
			label: "rejected",
		},
		suspend: {
			style: { color: 'gray' },
			label: "Suspend",
		},
	},
};

const styles = {
};

export default StatusField;
