import { get } from "lodash-es";
import { processUser } from "./user";

const statusMap = {
    'pending':"pending",
    "verified":"verified",
    "rejected":"rejected"
    
}
export const processComment = (rawData) => {
	return {
		user: get(rawData, "user"),
		createdAt: get(rawData, "createdAt"),
		comment: get(rawData, "content"),
		name: get(rawData, ":user.name"),
		avatar: get(rawData, ":user.avatar"),
		userDetail: processUser(get(rawData, ":user")),
		office: get(rawData, "office"),
		rating: get(rawData, "rating"),
		type: get(rawData, "type") === "o" ? "office" : "host",
		id: get(rawData, "id"),
		ratingDetail: get(rawData, ":details"),
		status: statusMap[get(rawData, "status")] || get(rawData, "status"),
		rawData,
	};
};
