import * as React from "react";
import {
	List,
	Datagrid,
	TextField,
	EmailField,
	TextInput,
	ReferenceInput,
	SelectInput,
	SimpleList,
	useListContext,
	EditButton,
	ShowButton,
	CloneButton,
    NumberField,
    DateField
} from "react-admin";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";


const postFilters = [
	<TextInput source="q" label="Search" alwaysOn />,
	// <ReferenceInput source="userId" label="Host" reference="users" allowEmpty>
	// 	<SelectInput optionText="name"/>
	// </ReferenceInput>,
];

const rowClick = (id, basePath, record) => {
	return "show";
};

const PostListActionToolbar = ({ children, ...props }) => {
	return (
		<div>
			{React.Children.map(children, (button) => React.cloneElement(button, props))}
		</div>
	);
};

        // id:1,
        // referenceId:"100007801",
        // clientName:'Firstname Lastname',
        // clientType:'individual',
        // contact:'',
        // mobile:6140080808,
        // email:'',
        // bookings:'3/20',
        // registration:"28/2/20",
        // lastestBooking:new Date('2021/10/20').toISOString(),
        // totalSpending:8970.21,
        // requests:"0/0"

const HostList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
	return (
		<List filters={postFilters} {...props}>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => `${record.id}`}
					tertiaryText={(record) => record.website}
				/>
			) : (
				<Datagrid rowClick="edit" rowClick={rowClick}>
					<TextField source="reference" />
					<TextField source="hostName" />
					<TextField source="id" label="System id" />
					<TextField source="hostType" />
					<TextField source="contact" />
					<TextField source="mobile" />
					<EmailField source="email" />
					<TextField source="bookings" />
					<DateField source="registration" />
					<DateField source="lastestBooking" />
					<NumberField
						source="totalEarning"
						options={{ style: "currency", currency: "AUD" }}
					/>
					<TextField source="requests" />
					<PostListActionToolbar>
						<EditButton />
						<ShowButton />
					</PostListActionToolbar>
				</Datagrid>
			)}
		</List>
	);
}

export default HostList