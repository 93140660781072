import AnnouncementList from "./AnnouncementList";
import AnnouncementShow from "./AnnouncementShow";
import AnnouncementEdit from "./AnnouncementEdit";
import AnnouncementCreate from "./AnnouncementCreate";

export default {
	list: AnnouncementList,
	show: AnnouncementShow,
	edit: AnnouncementEdit,
	create: AnnouncementCreate,
};
