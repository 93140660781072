import * as React from "react";
import {
	Identifier,
	Datagrid,
	DateField,
	TextField,
	DatagridProps,
	BooleanField,
	SelectField,
	NumberField,
	ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";


const useStyle = makeStyles({
	headerRow: {
		borderLeftColor: "transparent",
		borderLeftWidth: 5,
		borderLeftStyle: "solid",
	},
	headerCell: {
		padding: "6px 8px 6px 8px",
	},
	rowCell: {
		padding: "6px 8px 6px 8px",
	},
	name: {
		maxWidth: "14em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
});

const ServiceListDesktop = ({ selectedRow, ...props }) => {
	const classes = useStyle();
	return (
		<Datagrid
			rowClick="show"
			// rowStyle={rowStyle(selectedRow)}
			classes={{
				headerRow: classes.headerRow,
				headerCell: classes.headerCell,
				rowCell: classes.rowCell,
			}}
			optimized
			{...props}
		>
			<DateField source="createdAt" label="Date" sortable={false} />
			<TextField
				source="name"
				cellClassName={classes.name}
				sortable={false}
			/>
			<SelectField
				sortable={false}
				source="type"
				choices={[
					{ id: "privateRoom", name: "Private room" },
					{ id: "hotDesk", name: "Hot desk" },
					{ id: "meetingRoom", name: "Meeting room" },
					{ id: "parking", name: "Parking" },
				]}
			/>
			<ReferenceField
				label="Office"
				source="office"
				reference="office"
				sortable={false}
				link="show"
			>
				<TextField source="officeInfo.headline" />
			</ReferenceField>
			<TextField source="capacity" sortable={false} />
			<NumberField
				source="perHour"
				sortable={false}
				options={{ style: "currency", currency: "AUD" }}
			/>
			<NumberField
				source="perDay"
				sortable={false}
				options={{ style: "currency", currency: "AUD" }}
			/>
			<NumberField
				source="perMonth"
				sortable={false}
				options={{ style: "currency", currency: "AUD" }}
			/>
			<NumberField
				source="cancelPercentage"
				sortable={false}
				options={{ style: "percent" }}
			/>
			<BooleanField source="active" />
		</Datagrid>
	);
};

export default ServiceListDesktop;
